import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import './Forms.scss';

import { useTranslation } from 'react-i18next';

function Maintenance(props) {
  const { t } = useTranslation();

  return (
    <div className="menu">
      <div className="app forms">
        <header className="admin-header">
          <figure className="logo">
            <img className="admin-logo" src={t('common.logo_url', '/images/logo.svg')} alt={t('common.site_name', 'Menu Autêntico')} />
          </figure>
        </header>
        <div className="content">
          <div className="form">
            <div className="m-bottom-6 txt-primary  txt-center">
              <h2>Plataforma em manutenção.<br/><br/>Por favor aguarde.</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Maintenance;