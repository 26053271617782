import { gql } from "@apollo/client";
import initializeApollo from "../lib/initApollo";

const USER_DATA = `
    id
    name
    nif
    code
`;

const GET = gql`
  query get($id: ID!) {
    user(where: { id: $id }) {
      ${USER_DATA}
    } 
  }
`;

const GET_BY_FILTER = gql`
  query get($filter: UserWhereInput!) {
    users(where: $filter) {
      ${USER_DATA}
    }
  }
`;

const CREATE_MUTATION = gql`
	mutation create($data: UserWhereInput!) {
		createUser(data: $data) {
			${USER_DATA}
		}
	}
`;

const UPDATE_MUTATION = gql`
	mutation update($id: ID!, $data: UserUpdateInput!) {
		updateUser(where: { id: $id }, data: $data) {
			id
		}
	}
`;

const DELETE_MUTATION = gql`
	mutation delete($id: ID!) {
		deleteUser(where: { id: $id }) {
			${USER_DATA}
		}
	}
`;

const apolloClient = initializeApollo();

export default {
    getById: async (id) => {
        const {
            data: { user },
        } = await apolloClient.query({
            query: GET,
            variables: { id },
        });

        return user;
    },
    getByEmail: async (email) => {
        const {
            data: { users },
        } = await apolloClient.query({
            query: GET_BY_FILTER,
            variables: { email }
        });

        return users;
    },
    validateUserById: async (user) => {
        const {
            data: { updateUser },
        } = await apolloClient.query({
            query: UPDATE_MUTATION,
            variables: { 
                id: user.id, 
                data: {
                    email_validated: true
                }
            }
        });

        return updateUser;
    },
    add: async (data) => {
        const {
            data: { createUser },
        } = await apolloClient.query({
            query: CREATE_MUTATION,
            variables: { data },
        });

        return createUser;
    },
    update: async (id, data) => {
        const {
            data: { updateUser },
        } = await apolloClient.query({
            query: UPDATE_MUTATION,
            variables: { id, data }
        });

        return updateUser;
    },
    delete: async (id) => {
        const {
            data: { deleteUser },
        } = await apolloClient.query({
            query: DELETE_MUTATION,
            variables: { id }
        });

        return deleteUser;
    }
};