import { gql } from "@apollo/client";
import initializeApollo from "../lib/initApollo";

const MATERIAL_DATA = `{
    id
	name
	max
	group
	image {
		url
	}
}`;

const GET_ALL = gql`
    query {
        materials ${MATERIAL_DATA}
    }
`;

const apolloClient = initializeApollo();

const services = {
    getAll: async () => {
		const {
			data: { materials },
		} = await apolloClient.query({
			query: GET_ALL
		});

		return materials;
	},
};

export default services;