// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app .content .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.app .content .row .label {
  width: 60%;
}
.app .content .row .label h2 {
  font: 1.4em/1 vigaregular;
  margin-right: 10%;
  color: #fff;
}
.app .content .row .label h2 a {
  color: #caa36c;
}
.app .content .row .options {
  width: 40%;
  display: flex;
  align-items: initial;
  justify-content: flex-end;
  /*
      .option {
          background-color: #f5f5f5;
          border-radius: 5px;
          border: 0;
          width: 50%;
          height: 50px;
          color: #fff;
          font: 1.7em/1 vigaregular;
          color: #5c5c5c;
          transition: all 0.3s;

          &.selected {
              background-color: #caa36c;
              color: #fff;
          }

          &:last-child {
              margin-left: 5%;
          }
      }
  */
}`, "",{"version":3,"sources":["webpack://./src/components/admin/Signup.scss"],"names":[],"mappings":"AAEQ;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;AADZ;AAGY;EACI,UAAA;AADhB;AAGgB;EACI,yBAAA;EACA,iBAAA;EACA,WAAA;AADpB;AAGoB;EACI,cAAA;AADxB;AAKY;EACI,UAAA;EACA,aAAA;EACA,oBAAA;EACA,yBAAA;EAEJ;;;;;;;;;;;;;;;;;;;;;GAAA;AAiBZ","sourcesContent":[".app {\n    .content {\n        .row {\n            display: flex;\n            align-items: center;\n            justify-content: space-between;\n\n            .label {\n                width: 60%;\n\n                h2 {\n                    font: 1.4em/1 vigaregular;\n                    margin-right: 10%;\n                    color: #fff;\n\n                    a {\n                        color: #caa36c;\n                    }\n                }\n            }\n            .options {\n                width: 40%;\n                display: flex;\n                align-items: initial;\n                justify-content: flex-end;\n\n            /*\n                .option {\n                    background-color: #f5f5f5;\n                    border-radius: 5px;\n                    border: 0;\n                    width: 50%;\n                    height: 50px;\n                    color: #fff;\n                    font: 1.7em/1 vigaregular;\n                    color: #5c5c5c;\n                    transition: all 0.3s;\n\n                    &.selected {\n                        background-color: #caa36c;\n                        color: #fff;\n                    }\n\n                    &:last-child {\n                        margin-left: 5%;\n                    }\n                }\n            */\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
