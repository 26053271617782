import React, { useEffect, useState } from 'react';

import { withTranslation } from 'react-i18next';

import Carousel from "@itseasy21/react-elastic-carousel";

import api from '../../../services';

const ITEMS_PER_PAGE = 20;

function Tabs(props) {
    const { t } = props;

    const [state, setState] = useState({
        data: null,
        loading: true,
        menu: null,
        list: null,
        label: null,
        style: null,
        isSubTab: null,
    });

    useEffect(() => {
        setState({
            data: props.items,
            loading: false,
            menu: props.menu,
            list: props.list,
            label: props.label,
            style: props.style,
            isSubTab: props.subtab,
        })
    }, [props]);

    const sortByOrder = items => {
        items.sort(function (a, b) {
            if (a.order === null) {
                let max = items.length;
                if (a.order > items.length || b.order > items.length) max = (a.order < b.order ? b.order : a.order) + 1;
                a.order = max;
            }
            return a.order - b.order;
        });
        return items;
    }

    const chunkArray = (myArray, chunk_size) => {
        var results = [], tmpArray = myArray.concat();

        while (tmpArray.length) {
            results.push(tmpArray.splice(0, chunk_size));
        }
        return results;
    }

    const onSortTabs = e => {
        e.preventDefault();
        props.sortTabs(state.data);
    }

    const showSortButton = (label, style) => {
        if (state.data.length > 1) {
            return (
                <div className="footer-buttons m-top-4">
                    <div onClick={onSortTabs} className="btn-secundary" style={style.buttons.border}>
                        <span>{label}</span>
                    </div>
                </div>
            )
        }
    }

    const checkDefault = (tab, style) => {
        if (!tab.default) {
            return (
                <a
                    data-id={tab.id}
                    href="#"
                    className="edit"
                    onClick={props.editTab}
                >
                    <span className="icon-edit" style={style}></span>
                </a>
            );
        }
    }

    const { data, style, loading, isSubTab } = state;

    if (!loading && data && data) {
        const ordered = sortByOrder(data);
        const pages = chunkArray(ordered, ITEMS_PER_PAGE);

        if (pages.length === 0) {
            return (
                <div className="tabs">
                    <ul key="0" className="icons">
                        <li>
                            <a href="#" className="item new" onClick={props.addTab} style={{ backgroundColor: style.isDarkMode ? "#5C5C5C" : "#fff" }}>
                                <div className="button">
                                    <figure className="cross" style={style.buttons.border}></figure>
                                    <span style={style.buttons.color}>{isSubTab ? t('btn.add_sub_tab', 'Adicionar Sub Separador') : t('btn.add_tab', 'Adicionar Separador')}</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            );
        }

        const pagesHtml = pages.map((page, index) => {
            return (
                <ul key={index} className={data.length < 8 ? 'icons' : 'icons col-3'}>
                    <li>
                        <a href="#" className="item new" onClick={props.addTab} style={{ backgroundColor: style.isDarkMode ? "#5C5C5C" : "#fff" }}>
                            <div className="button">
                                <figure className="cross" style={style.buttons.border}></figure>
                                <span style={style.buttons.color}>{isSubTab ? t('btn.add_sub_tab', 'Adicionar Sub Separador') : t('btn.add_tab', 'Adicionar Separador')}</span>
                            </div>
                        </a>
                    </li>
                    {page.map(list => {
                        return (
                            <li key={list.id} style={{ opacity: list.hide ? '0.75' : '1' }}>
                                <a className="item" href={list.subtab ? "/admin/tab/" + list.id : "/admin/detail/" + list.id} style={style.buttons.background}>
                                    <div className="button">
                                        <img
                                            className="icon"
                                            src={'/images/icons/' + list.icon.image + '.svg'}
                                            alt=""
                                            style={{ filter: style.isDarkMode ? 'invert(0.7)' : 'invert(0)' }}
                                        />
                                        {process.env.REACT_APP_VERSION != 'sb:user:en' ? <span style={style.text}>{list.name_pt}</span> : <span style={style.text}>{list.name_en}</span>}
                                    </div>
                                </a>
                                {checkDefault(list, style.text)}
                            </li>
                        )
                    })}
                </ul>
            )
        });

        return (
            <div className="tabs">
                <Carousel showArrows={false} pagination={pages.length > 1 ? true : false} enableSwipe={true} enableMouseSwipe={true}>{pagesHtml}</Carousel>
                {showSortButton(isSubTab ? t('btn.sort_sub_tabs', 'Ordenar sub separadores') : t('btn.sort_tabs', 'Ordenar separadores'), style)}
            </div>
        );
    }
    else {
        return (
            <div className="tabs">
                <div className="loader">
                    <img src="/images/loader.gif" alt="" />
                </div>
            </div>
        );
    }
}

export default withTranslation()(Tabs);