import { gql } from "@apollo/client";
import initializeApollo from "../lib/initApollo";

const ORDER_DATA = `
    id
    name
    group
    stock
    max
    image {
        publicUrl
    }
`;

const REPORT_DATA = `
    id
    order
    product_1 { name }
    quantity_1
    product_2 { name }
    quantity_2
    product_3 { name }
    quantity_3
    name
    address
    contact
    postal
    city
    menu { 
        id 
        qrcode 
        restaurant 
        user { name } 
    }
    createdAt
    sent
`;

const GET_REPORT = gql`
  query getAll {
    orders {
      ${REPORT_DATA}
    }
  }
`;

const GET_BY_FILTER = gql`
  query get($filter: OrderWhereInput!) {
    orders(where: $filter) {
      ${ORDER_DATA}
    }
  }
`;

const CREATE_MUTATION = gql`
	mutation create($data: OrderCreateInput!) {
		createOrder(data: $data) {
			id
		}
	}
`;

const UPDATE_MUTATION = gql`
	mutation update($id: ID!, $data: OrderUpdateInput!) {
		updateOrder(where: { id: $id }, data: $data) {
			id
		}
	}
`;

const DELETE_MUTATION = gql`
	mutation delete($id: ID!) {
		deleteOrder(where: { id: $id }) {
			id
		}
	}
`;

const apolloClient = initializeApollo();

const services = {
	getReport: async () => {
		const {
			data: { orders },
		} = await apolloClient.query({
			query: GET_REPORT,
		});

		return { allOrders: orders };
	},
	getAllByUserId: async (id) => {
		const {
			data: { orders },
		} = await apolloClient.query({
			query: GET_BY_FILTER,
			variables: { client: { id } },
		});

		return orders;
	},
	update: async (data) => {
		const {
			data: { updateOrder },
		} = await apolloClient.query({
			query: UPDATE_MUTATION,
			variables: {
				id: data.id,
				data: { sent: data.sent },
			},
		});

		return updateOrder;
	},
	delete: async (id) => {
		const {
			data: { deleteOrder },
		} = await apolloClient.query({
			query: DELETE_MUTATION,
			variables: { id },
		});

		return deleteOrder;
	},
	add: async (data) => {
		const {
			data: { createOrder },
		} = await apolloClient.query({
			query: CREATE_MUTATION,
			variables: { data },
		});

		return createOrder;
	},
};

export default services;
