import React from "react";
import "./Loading.scss";

const Loading = () => (
	<div className="loading-container">
		<div className="spinner"></div>
	</div>
);

export default Loading;
