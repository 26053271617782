// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup .select-item {
  padding: 10% 5%;
  min-height: 250px;
}
.popup .select-item h2 {
  font: 2em/1 vigaregular;
  text-align: left;
  color: #5c5c5c;
  margin: 6% 0;
}
.popup .select-item .row {
  display: flex;
}
.popup .select-item .row .btn-primary {
  font-size: 1.5em;
  padding: 20px 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
}
.popup .select-item .row .btn-primary:last-child {
  margin-right: 0px;
}
.popup .select-item .row .btn-primary span.label {
  width: 100%;
}
.popup .select-item .row .btn-primary span.icon-drinks,
.popup .select-item .row .btn-primary span.icon-arrow-down {
  color: white;
  display: inline-block;
  font-size: 3em;
  margin: 0 auto 15px auto;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/popups/SelectItemContent.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,iBAAA;AAAR;AAEQ;EACI,uBAAA;EACA,gBAAA;EACA,cAAA;EACA,YAAA;AAAZ;AAGQ;EACI,aAAA;AADZ;AAEY;EACI,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;AAAhB;AACgB;EACI,iBAAA;AACpB;AACgB;EACI,WAAA;AACpB;AACgB;;EAEI,YAAA;EACA,qBAAA;EACA,cAAA;EACA,wBAAA;AACpB","sourcesContent":[".popup {\n    .select-item {\n        padding: 10% 5%;\n        min-height: 250px;\n\n        h2 {\n            font: 2em/1 vigaregular;\n            text-align: left;\n            color: #5c5c5c;\n            margin: 6% 0;   \n        }\n\n        .row{\n            display: flex;\n            .btn-primary{\n                font-size: 1.5em;\n                padding: 20px 20px;\n                margin-right: 10px;\n                display: flex;\n                align-items: center;\n                flex-wrap: wrap;\n                text-align: center;\n                width: 100%;\n                &:last-child{\n                    margin-right: 0px;\n                }\n                span.label{\n                    width: 100%;\n                }\n                span.icon-drinks,\n                span.icon-arrow-down{\n                    color: white;\n                    display: inline-block;\n                    font-size: 3em;\n                    margin: 0 auto 15px auto;\n                }\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
