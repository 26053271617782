import React, { useEffect, useState } from "react";

import { withTranslation } from "react-i18next";

import Icons from "../partials/Icons";

import api from "../../../services";

import "./AddTabContent.scss";

import { toast } from "react-toastify";

function EditTabContent(props) {
	const { t } = props;

	const [state, setState] = useState({
		loading: true,
		show_english: props.data.show_english,
		errors: { name_pt: "", name_en: "" },
		sub_tab: false,
		hide_tab: false,
		parent: null,
		name_pt: "",
		name_en: "",
		icon: "",
	});

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		const id = props.data.id;

		const list = await api.itemlist.getById(id);

		const name_pt = list.name_pt;
		const name_en = list.name_en;
		const icon = list.icon.id;
		const sub_tab = list.subtab;
		const parent = list.parent;
		const hide_tab = list.hide;

		setState({
			...state,
			name_pt,
			name_en,
			icon,
			loading: false,
			sub_tab,
			parent,
			hide_tab,
		});
	};

	const validate = () => {
		let { name_pt, name_en, icon, show_english } = state;

		let isValidNamePT = true;
		if (process.env.REACT_APP_VERSION === "sb:user" || show_english) {
			isValidNamePT = name_pt.length == 0 ? null : true;
			if (!isValidNamePT) toast.error(t("Nome do tab inválido!"));
		}

		let isValidNameEN = true;
		if (show_english) {
			isValidNameEN = name_en.length === 0 ? null : true;
			if (!isValidNameEN) toast.error(t("Name of the tab is invalid!"));
		}

		const isValidIcon = icon === "" ? null : true;
		if (!isValidIcon)
			toast.error(t("error_msg.select_icon", "Tem de escolher um icon."));

		return isValidNamePT && isValidNameEN && isValidIcon;
	};

	const handleFocus = (event) => {
		const errors = state.errors;
		const { name } = event.target;
		errors["name_" + name] = "";
		setState({ ...state, errors });
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!validate()) return;

		setState({ ...state, loading: true });

		const data = {
			name_pt: state.name_pt,
			name_en: state.name_en,
			subtab: state.sub_tab,
			hide: state.hide_tab,
		};

		if (state.icon) {
			data.icon = { connect: { id: state.icon } };
		}

		console.log(state);

		const update = await api.itemlist.update(props.data.id, data);

		if (update && update.id) {
			props.onComplete();
		} else console.log("Error updating Label");
	};

	const deleteTab = (event) => {
		event.preventDefault();
		props.data.onDelete(props.data.id);
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setState({ ...state, [name]: value.replace('"', "") });
	};

	const handleLanguage = (event) => {
		const { name, value } = event.target;
		setState({ ...state, ["name_" + name]: value.replace('"', "") });
	};

	const handleCheckbox = (event) => {
		const target = event.target;
		const value =
			target.name === "sub_tab" || target.name === "hide_tab"
				? target.checked
				: target.value;
		const name = target.name;

		setState({
			...state,
			[name]: value,
		});
	};

	const { show_english, loading, sub_tab, parent, hide_tab } = state;

	return (
		<div className={loading ? "add-menu loading" : "add-menu"}>
			<h2>{t("admin_edit_tab.title", "Editar separador")}</h2>
			<form autoComplete="off">
				<input
					type="text"
					placeholder="Nome do separador"
					name="pt"
					onChange={handleLanguage}
					onFocus={handleFocus}
					value={state.name_pt}
					style={
						process.env.REACT_APP_VERSION === "sb:user:en" &&
						!props.data.show_english
							? { display: "none" }
							: null
					}
				/>

				{show_english ||
				process.env.REACT_APP_VERSION === "sb:user:en" ? (
					<input
						type="text"
						placeholder="Name of the tab"
						name="en"
						onChange={handleLanguage}
						onFocus={handleFocus}
						value={state.name_en}
						className="m-top-4"
					/>
				) : null}
			</form>
			<div className="m-top-8">
				<h2>
					{t("admin_edit_tab.txt_select_icon", "Escolha o ícone")}
				</h2>
			</div>
			{!loading ? (
				<Icons
					selected={state.icon}
					onChange={(id) => {
						setState({ ...state, icon: id });
					}}
				/>
			) : (
				""
			)}
			{!parent ? (
				<div className="row-checkbox m-top-4">
					<input
						type="checkbox"
						id="sub_tab"
						name="sub_tab"
						onChange={handleCheckbox}
						checked={sub_tab}
					/>
					<label className="label-checkbox" htmlFor="sub_tab">
						{t("placeholder.sub_tabs", "Usar sub-separadores")}
					</label>
				</div>
			) : (
				""
			)}
			<div className="row-checkbox m-top-4">
				<input
					type="checkbox"
					id="hide_tab"
					name="hide_tab"
					onChange={handleCheckbox}
					checked={hide_tab}
				/>
				<label className="label-checkbox" htmlFor="hide_tab">
					{parent
						? t("placeholder.hide_sub_tab", "Ocultar sub-separador")
						: t("placeholder.hide_tab", "Ocultar separadores")}
				</label>
			</div>
			<div className="m-top-4">
				<a onClick={handleSubmit} className="btn-primary">
					{t("btn.save", "Guardar")}
				</a>
			</div>
			<div className="m-top-4">
				<a href="#" onClick={deleteTab} className="btn-tertiary">
					{t("btn.delete", "Eliminar")}
				</a>
			</div>
		</div>
	);
}

export default withTranslation()(EditTabContent);
