import React, { useEffect, useState } from 'react';

import { withTranslation } from 'react-i18next';

import Popup from './popups/Popup';
import Styles from "../utils/styles";

import './AdminDetail.scss';
import api from '../../services';

function AdminDetail(props) {
  const { t } = props;

  const [id, setId] = useState(props.match.params.id);

  const [state, setState] = useState({
    loading: false,
    currency: '€',
    items: null,
    popup: null,
    data: null
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setState({ ...state, loading: true });

    const list = await api.itemlist.getById(id);
    const items = sortByOrder(list.items);

    setState({ ...state, data: list, items: items, loading: false, popup: null });
  }

  const sortByOrder = items => {
    items.sort(function (a, b) {
      if (a.order === null) {
        let max = items.length;
        if (a.order > items.length || b.order > items.length) max = (a.order < b.order ? b.order : a.order) + 1;
        a.order = max;
      }
      return a.order - b.order;
    });
    return items;
  }

  const closePopup = e => {
    if (e) {
      if (e.popup == "pick" && e.item.id) {
        //open popup after product selected
        const show_english = state.data && state.data.menu.show_english;
        setState({ ...state, popup: { name: 'edit_item', id: e.item.id, show_english } });
      } else if (e.popup == "delete" && e.id) {
        setState({ ...state, popup: { name: 'delete', id: e.id, onDelete: deleteItem } });
      } else if (e.popup == "addItem" && e.item.id) {
        //this.setState({ popup: { name: 'add_sub_item_question', id: e.item.id, onAddSubItems: this.addSubItems } });
        setState({ ...state, popup: null, items: null });
        getData();
      } else if (e.popup == "change_tab" && e.id) {
        setState({ ...state, popup: { name: 'change_tab', id: e.id, tab: e.tab, onChangedTab: tabChanged } });
      } else {
        e.preventDefault();
        getData();
      }
    } else {
      getData();
    }
  }

  const tabChanged = value => {
    console.log('tabChanged', value);
    setId(value);
    setState({ ...state, popup: null, items: null });
    getData();
  }

  const editTab = e => {
    e.preventDefault();
    const { currentTarget } = e;
    const show_english = state.data && state.data.menu.show_english;

    setState({
      ...state, 
      popup: {
        name: "edit_tab",
        id: currentTarget.dataset.id,
        show_english,
        onDelete: confirmDeleteTab,
      },
    });
  }

  const confirmDeleteTab = e => {
    setState({
      ...state, 
      popup: { name: "delete", id: id, onDelete: deleteTab },
    });
  }

  const deleteTab = id => {
    // services.itemListApi
    //   .delete(id)
    //   .then(({ deleteItemList }) => {
    //     if (deleteItemList && deleteItemList.id) {
    //       this.closePopup();
    //     } else console.log("Error deleting ItemList");
    //   })
    //   .catch((error) => console.log(error));
  }

  const addItem = e => {
    e.preventDefault();
    const show_english = state.data && state.data.menu.show_english;
    setState({ ...state, popup: { name: 'add_item', list: id, show_english } });
  }

  const pickItem = () => {
    const show_english = state.data && state.data.menu.show_english;
    setState({ ...state, popup: { name: 'pick_item', list: id, show_english } });
  }

  const selectItem = e => {
    e.preventDefault();
    const show_english = state.data && state.data.menu.show_english;
    setState({ ...state, popup: { name: 'select_item', list: id, show_english, onAddItem: addItem, onPickItem: pickItem } });
  }

  const editItem = e => {
    const { currentTarget } = e;
    const show_english = state.data && state.data.menu.show_english;
    setState({ ...state, popup: { name: 'edit_item', id: currentTarget.dataset.id, show_english, parent: state.data } });
  }

  const deleteItem = async id => {
    const result = await api.items.delete(id);

    if (result && result.id) closePopup();
    else console.log("Error deleting Item");
  }

  const confirmDelete = e => {
    e.preventDefault();
    const { currentTarget } = e;
    const id = currentTarget.dataset.id;

    setState({ ...state, popup: { name: 'delete', id: id, onDelete: deleteItem } });
  }

  const sortItems = e => {
    setState({ ...state, popup: { name: 'sort_detail', list: state.items, onSave: closePopup } });
  }

  const showSortButton = (label, style) => {
    if (state.items && state.items.length > 1) {
      return (
        <div className="bottom-btn" onClick={sortItems} style={style.buttons.border}>
          <span>{label}</span>
          <span className="icon-sort"></span>
        </div>
      )
    }
  }

  const hasThumbnail = data => {
    let show = false;
    for (let i = 0; i < data.length; i++) {
      if (data[i].image && data[i].image != 'null') show = true;
    }
    return show;
  }

  const renderItems = (items, currency, style) => {
    if (items && items.length) {
      const showThumbnail = hasThumbnail(items);
      return (
        <ul>
          {items.map(item => (
            <li key={item.id} style={{ opacity: item.visible === false ? 0.5 : 1 }}>
              <div className={showThumbnail ? 'item thumb' : 'item no-thumb'}>
                {showThumbnail ? <div className="thumb"><figure>{item.image && item.image != 'null' ? <img src={item.image} /> : <img src={'/images/placeholder.png'} />}</figure></div> : ''}
                <div className={item.items.length > 0 ? "details full" : "details"}>
                  <div className="plate edit-btn">
                    <h3>{process.env.REACT_APP_VERSION != 'sb:user:en' ? item.name_pt : item.name_en}</h3>
                    <p>{process.env.REACT_APP_VERSION != 'sb:user:en' ? item.description_pt : item.description_en}</p>
                  </div>
                  {
                    item.items.length == 0 ?
                      <div className="price" style={style.color}>
                        <h4>{currency} {item.price}</h4>
                      </div> : null
                  }
                  {
                    item.items.length != 0 ?
                      <ul className="multi">
                        {item.items.map(sub => (
                          <li className="product-item" key={sub.id}>
                            <div className="product-label">{process.env.REACT_APP_VERSION != 'sb:user:en' ? sub.name_pt : sub.name_en}</div>
                            <div className="product-price" style={style.color}>{currency} {sub.price}</div>
                          </li>
                        ))}
                      </ul> : null
                  }
                </div>
              </div>
              <nav className="actions">
                <div data-id={item.id} onClick={editItem} >
                  <span className="icon-edit-bold" style={style ? style.color : ''}></span>
                </div>
                {/*
                <div data-id={item.id} onClick={confirmDelete} style={style.border}>
                  <img src="/images/admin/icons/delete.png" alt="Apagar" />
                </div>
                */}
              </nav>
            </li>
          ))}
        </ul>
      );
    }
  }

  const checkDefault = item => {
    if (!item.default) {
      return (
        <div data-id={item.id} className="edit" onClick={editTab}><img src="/images/admin/icons/edit-white.png" alt="Editar" /></div>
      );
    }
  }

  const backUrl = data => {
    let url = "";
    if (data.parent) url = "/admin/tab/" + data.parent.id;
    else if (data.subtab) url = "/admin/tab/" + data.id;
    else url = "/admin/menu/" + data.menu.id;
    return url;
  }

  const { data, currency, popup, items, loading } = state;

  if (data) {
    const style = Styles.checkStyle(data.menu);
    return (
      <div className="menu" style={style.background.backgroundColor || style.background.backgroundImage ? { backgroundColor: '#eee' } : null}>
        <div className="app" style={style.background}>
          <header className="detail-header">
            <a className="back" href={backUrl(data)}>
              <span className="icon-arrow-back" style={style ? style.customText : ""}></span>
            </a>
            <div className="back"></div>
          </header>
          <div className="list">
            <div className="title" style={style ? style.buttons.background : {}}>
              <img className="sep-icon" src={'/images/icons/' + data.icon.image + '.svg'} alt={process.env.REACT_APP_VERSION != 'sb:user:en' ? data.name_pt : data.name_en} style={{ filter: style && style.isDarkMode ? 'invert(0.7)' : 'invert(0)' }} />
              <h2 style={style.text}>{process.env.REACT_APP_VERSION != 'sb:user:en' ? data.name_pt : data.name_en}</h2>
              {/* {this.checkDefault(data)} */}
            </div>
            <div className="content">
              <div className={loading ? "list-wrap loading" : "list-wrap"}>
                {renderItems(items, currency, style.buttons)}
              </div>
              <div className="bottom-buttons m-top-4">
                <div className="bottom-btn" onClick={addItem} style={style.buttons.border}>
                  <span>{t('btn.add_product', 'Adicionar Produto')}</span>
                  <span className="icon-add"></span>
                </div><div className="bottom-btn" onClick={pickItem} style={style.buttons.border}>
                  <span>{t('btn.add_sb_product', 'Adicionar produto Super Bock')}</span>
                  <span className="icon-add"></span>
                </div>
                {!loading ? showSortButton(t('btn.sort_products', 'Ordenar produtos'), style) : null}
              </div>
            </div>
            <footer></footer>
          </div>
          <figure className="banner">
            <img src="/images/banner-stout.png" alt="Beber para Crer" />
          </figure>
          <Popup data={popup} onClose={closePopup} style={style} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="loader">
        <img src="/images/loader.gif" alt="" />
      </div>
    );
  }
}

export default withTranslation()(AdminDetail);