import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import "./Signup.scss";

import { ServicesContext } from "../../context/services";

import { withTranslation } from "react-i18next";

import { toast } from "react-toastify";

class ForgotPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			password: "",
			password2: "",
			errors: {},
			success: false,
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	validate() {
		const { password, password2 } = this.state;
		const { t } = this.props;

		const isValidPassword = password.length < 8 ? null : true;
		if (!isValidPassword) {
			toast.error(
				t(
					"error_msg.password_min",
					"Password inválida (minímo de 8 caracteres)!"
				)
			);
		}

		const passwordsMatch = password === password2;
		if (isValidPassword && !passwordsMatch) {
			toast.error(
				t("error_msg.password_equal", "As passwords não são iguais.")
			);
		}
	}

	handleFocus(event) {
		const { name } = event.target;
		const { errors } = this.state;
		errors[name] = "";
		this.setState({ errors });
	}

	handleChange(event) {
		const { name, value } = event.target;
		this.setState({ [name]: value });
	}

	handleSubmit(event) {
		event.preventDefault();

		if (!this.validate()) return;

		const { services } = this.context;
		const id = this.props.match.params.id;
		const password = this.state.password;
		const { t } = this.props;

		services.authApi
			.changeUserPasswordById(id, password)
			.then(({ updateUser }) => {
				if (updateUser) {
					this.setState({ success: true });
					toast.success(t("Password alterada com sucesso!"));
				} else
					toast.error(
						t(
							"error_msg.password_not_changed",
							"Não foi possível alterar password"
						)
					);
			})
			.catch((error) => {
				console.error(error);
				toast.error(
					t("error_msg.generic", "Ocorreu um erro. Tente novamente.")
				);
			});
	}

	render() {
		const { success } = this.state;
		const { t } = this.props;

		if (!success) {
			return (
				<div className="menu">
					<div className="app forms solo">
						<header className="admin-header">
							<figure className="logo">
								<img
									className="admin-logo"
									src={t(
										"common.logo_url",
										"/images/logo.svg"
									)}
									alt={t(
										"common.site_name",
										"Menu Autêntico"
									)}
								/>
							</figure>
						</header>
						<div className="content">
							<div className="form">
								<div className="title">
									<h2>
										{t(
											"admin_recover_password.title",
											"Alterar password"
										)}
									</h2>
								</div>
								<form>
									<input
										type="password"
										name="password"
										value={this.state.password}
										placeholder={t(
											"placeholder.new_password",
											"Nova Password"
										)}
										onChange={this.handleChange}
										onFocus={this.handleFocus}
									/>

									<input
										type="password"
										name="password2"
										value={this.state.password2}
										placeholder={t(
											"placeholder.confirm_password",
											"Confirmar password"
										)}
										onChange={this.handleChange}
										onFocus={this.handleFocus}
										className="m-top-4"
									/>
								</form>
								<div className="m-top-4">
									<div
										onClick={this.handleSubmit}
										className="btn-primary"
									>
										{t("btn.recover", "Recuperar")}
									</div>
								</div>
							</div>
							<div className="m-top-4 m-bottom-10">
								<a href="/login" className="btn-secundary">
									{t("btn.login", "Login")}
								</a>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return <Redirect to="/admin" />;
		}
	}
}

ForgotPassword.contextType = ServicesContext;

export default withTranslation()(ForgotPassword);
