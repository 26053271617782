import React, { useEffect, useState } from 'react';

import { withTranslation } from 'react-i18next';

import "./ChangeTabContent.scss";
import api from '../../../services';

function ChangeTabContent(props) {
    const { t } = props;

    const [state, setState] = useState({
        loading: false,
        data: props.data,
        show_english: props.data.show_english,
        currentMainId: null,
        currentSubId: null,
        alltabs: null,
        subtab: null,
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        if (state.data.tab.parent) {
            setState({ ...state, currentSubId: state.data.tab.id });
        } else {
            const tabs = await api.itemlist.getByMenu(state.data.tab.menu.id);
            
            if (tabs) {
                setState({
                    ...state,
                    alltabs: sortByOrder(tabs),
                    currentMainId: state.data.tab.id
                });
            }
        }
    }

    const sortByOrder = items => {
        items.sort(function (a, b) {
            if (a.order === null) {
                let max = items.length;
                if (a.order > items.length || b.order > items.length) max = (a.order < b.order ? b.order : a.order) + 1;
                a.order = max;
            }
            return a.order - b.order;
        });
        return items;
    }

    const onChangeTab = e => {
        setState({
            ...state,
            currentMainId: e.currentTarget.value, currentSubId: null
        });
    }

    const onChangeSubTab = e => {
        setState({
            ...state,
            currentSubId: e.currentTarget.value, currentMainId: null
        });
    }

    const saveChanges = async e => {
        e.preventDefault();

        const tab = state.currentSubId ? state.currentSubId : state.currentMainId;
        const id = state.data.id;

        const item = await api.items.update(id, { list: { connect: { id: tab } } });
        if(item && item.id) props.data.onChangedTab(tab);
    }

    const { subtab, alltabs, currentMainId, currentSubId } = state;

    console.log('currentMainId', currentMainId);
    console.log('currentSubId', currentSubId);

    return (
        <div className="change-tab">
            {alltabs ?
                <div className="">
                    {
                        subtab && subtab.length > 0 ?
                            <div className="">
                                <h2>{t('admin_change_tabs.txt_sub_tabs', 'Sub separadores')}</h2>
                                <div className="tabs m-top-4">
                                    {
                                        subtab.map(tab => {
                                            let checked = tab.id === currentSubId ? true : false;
                                            return (
                                                <div className="tab" key={tab.id}>
                                                    <input type="radio" id={tab.id} name="subtab" value={tab.id} checked={checked} onChange={onChangeSubTab} />
                                                    <label htmlFor={tab.id}>
                                                        <img className="icon" src={'/images/icons/' + tab.icon.image + '.svg'} alt="" />
                                                        <span>{tab.name_pt}</span>
                                                    </label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div> : null
                    }
                    {
                        alltabs ?
                            <div className="m-top-8">
                                <h2>{t('admin_change_tabs.txt_tabs', 'Separadores')}</h2>
                                <div className="tabs m-top-4">
                                    {
                                        alltabs.map(tab => {
                                            let checked = tab.id === currentMainId ? true : false;
                                            return (
                                                <div className="tab" key={tab.id}>
                                                    <input type="radio" id={tab.id} name="alltabs" value={tab.id} checked={checked} onChange={onChangeTab} />
                                                    <label htmlFor={tab.id}>
                                                        <img className="icon" src={'/images/icons/' + tab.icon.image + '.svg'} alt="" />
                                                        <span>{tab.name_pt}</span>
                                                    </label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div> : null
                    }
                    <div className="m-top-8">
                        <a onClick={saveChanges} className="btn-primary">
                            {t('btn.save', 'Guardar')}
                        </a>
                    </div>
                </div>
                :
                <div className="loader">
                    <img src="/images/loader.gif" alt="" />
                </div>
            }
        </div>
    )
}

export default withTranslation()(ChangeTabContent);