import React, { useEffect, useRef, useState } from 'react';

import Carousel from "@itseasy21/react-elastic-carousel";

import './Icons.scss';
import api from '../../../services';

const ITEMS_PER_SLIDE = 8;

function Icons(props) {
    // const { t } = props;

    const [state, setState] = useState({
        data: null,
        loading: true,
        selected: props.selected,
        style: props.style
    });

    const carousel = useRef(null);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const items = await api.icons.getAll();
        if(items) {
            setState({
                ...state,
                loading: false,
                data: { allIcons: items }
            });
        }
    }

    const handleClick = id => {
        setState({ ...state, selected: id });
        props.onChange(id);
    }

    // componentWillUpdate(nextProps) {
    //     let { selected } = this.state;
    //     if (selected !== nextProps.selected) {
    //         this.setState({ selected: nextProps.selected });
    //     }
    // }

    // componentDidUpdate(){
    //     if(this.state.style){
    //         const dots = document.body.getElementsByClassName('rec-dot');
    //         let i;
    //         if(dots.length > 0){
    //             for (i = 0; i < dots.length; i++) {
    //                 dots.item(i).style.backgroundColor = this.state.style.buttons.color['color'];
    //             }
    //         }
    //     }
    // }

    const setCarouselPos = pos => {
        // if (carousel && carousel.current) {
        //     carousel.current.goTo(Number(pos));
        // }
    }

    const chunkArray = (myArray, chunk_size) => {
        var results = [], tmpArray = myArray.concat();

        while (tmpArray.length) {
            results.push(tmpArray.splice(0, chunk_size));
        }
        return results;
    }

    const { data, style } = state;

    if (data && data.allIcons) {
        const pages = chunkArray(data.allIcons, ITEMS_PER_SLIDE);
        const pagesHtml = pages.map((page, index) => {
            return (
                <ul key={index} className="page">
                    {page.map(item => {
                        return (
                            <li key={item.id}>
                                <a href="#" onClick={(event) => handleClick(item.id)} className={item.id == state.selected ? "selected" : ""} style={item.id == state.selected && style ? style.buttons.background : null}>
                                    <div className={item.id == state.selected ? "icon-button selected" : "icon-button"}>
                                        <img className="icon" src={'/images/icons/' + item.image + '.svg'} />
                                    </div>
                                </a>
                            </li>
                        )
                    })}
                </ul>
            )
        });

        const selectedIndex = data.allIcons.findIndex(item => item.id === state.selected);
        const initIndex = Math.floor((selectedIndex) / ITEMS_PER_SLIDE);
        if (state.selected) setCarouselPos(initIndex);

        return (
            <div className="m-top-4">
                <ul className="admin-icons">
                    <Carousel ref={carousel} showArrows={false} pagination={pages.length > 1 ? true : false} enableSwipe={true} enableMouseSwipe={true} >{pagesHtml}</Carousel>
                </ul>
            </div>
        );
    }
    else {
        return (
            <div>
                <p>Loading...</p>
            </div>
        );
    }
}

export default Icons;