import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import queryString from "query-string";

import "./Forms.scss";

import { useTranslation } from "react-i18next";

import { useAuth } from "../../lib/auth";

import { toast } from "react-toastify";

function Login(props) {
	const [userName, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const [step, setStep] = useState(1);

	const { sendCode, signin } = useAuth();

	const query = queryString.parse(props.location.search);

	const { t } = useTranslation();

	function validate() {
		const pattern =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		switch (step) {
			case 1:
				const isValidEmail = pattern.test(userName);
				if (!isValidEmail) {
					toast.error(t("error_msg.email", "Email inválido!"));
				}
				return isValidEmail;
			case 2:
				const isValidPassword = password.length >= 6;
				if (!isValidPassword) {
					toast.error(t("error_msg.password", "Password inválida!"));
				}
				return isValidPassword;
		}

		return true;
	}

	function postLogin() {
		if (!validate()) return;

		switch (step) {
			case 1:
				sendCode({ email: userName })
					.then((result) => {
						if (result && result.sendCode) {
							setStep(2);
						}
					})
					.catch((e) => {
						toast.error(
							t(
								"error_msg.incorrect_login",
								"O email está incorrecto."
							)
						);
					});
				break;
			case 2:
				signin({
					email: userName,
					password: (password + "00").replace(/\s+/g, ""),
				})
					.then((result) => {
						console.log(result);
						if (result && result.item) {
							window.location.href = query.redirect
								? query.redirect
								: "/admin";
						} else {
							toast.error(
								t(
									"error_msg.incorrect_login",
									"O email ou password estão incorrectos."
								)
							);
						}
					})
					.catch((e) => {
						toast.error(
							t(
								"error_msg.incorrect_login",
								"O email ou password estão incorrectos."
							)
						);
					});
				break;
		}
	}

	return (
		<div className="menu">
			<div className="app forms">
				<header className="admin-header">
					<figure className="logo">
						<img
							className="admin-logo"
							src={t("common.logo_url", "/images/logo.svg")}
							alt={t("common.site_name", "Menu Autêntico")}
						/>
					</figure>
				</header>
				<div className="content">
					<div className="form">
						{step === 1 ? (
							<>
								<div className="m-bottom-6 txt-primary">
									<h2>{t("admin_login.title", "Login")}</h2>
									<form id="">
										<input
											type="email"
											id="userName"
											value={userName}
											placeholder={t(
												"placeholder.email",
												"E-mail"
											)}
											onChange={(e) =>
												setUserName(e.target.value)
											}
										/>
									</form>
								</div>
							</>
						) : (
							<>
								<h2>
									{t(
										"admin_login.verify",
										"Verifica a tua conta"
									)}
								</h2>
								<div className="m-top-8 txt-primary">
									<h3>
										{t(
											"admin_login.txt_code",
											"Por favor, introduza o código de verificação que foi enviado para o seu email."
										)}
									</h3>
								</div>
								<p></p>
								<form id="">
									<input
										type="password"
										id="code"
										value={password}
										placeholder={t(
											"placeholder.password",
											"Código"
										)}
										onChange={(e) =>
											setPassword(e.target.value)
										}
										className="m-top-4"
									/>
								</form>
							</>
						)}
						{/* <div className="m-top-8 txt-center">
              <a href="/forgot-password" className="link-primary">
                <span>{t('admin_login.txt_forgot', 'Esqueceu-se da password?')}</span>
              </a>
            </div> */}
						<div className="m-top-8">
							<a onClick={postLogin} className="btn-primary">
								{t("btn.login", "Login")}
							</a>
						</div>
					</div>
					<div className="m-top-8 txt-primary txt-center">
						<h5>
							{t(
								"admin_login.txt_signup",
								"Ainda não tem conta? Registe-se."
							)}
						</h5>
					</div>
					<div className="m-top-8 m-bottom-10">
						<a href="/signup" className="btn-secundary">
							{t("btn.signup", "Registar")}
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
