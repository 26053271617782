import React, { useState, useEffect } from "react";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
	useLocation,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import Menu from "./components/menu/Menu";

import Admin from "./components/admin/Admin";
import AdminMenu from "./components/admin/AdminMenu";
import AdminSubTab from "./components/admin/AdminSubTab";
import AdminDetail from "./components/admin/AdminDetail";

import Login from "./components/admin/Login";
import Signup from "./components/admin/Signup";
import Confirm from "./components/admin/Confirm";
import ForgotPassword from "./components/admin/ForgotPassword";
import RecoverPassword from "./components/admin/RecoverPassword";

import Orders from "./components/data/Orders";
import Menus from "./components/data/Menus";
import LandingPage from "./components/LandingPage";
import Maintenance from "./components/admin/Maintenance";
import Loading from "./components/Loading";

import { ApolloProvider } from "@apollo/client";
import { useApollo } from "./lib/apolloClient";
import { AuthProvider } from "./lib/auth";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import NotFoundPage from './components/NotFoundPage';

function App(props) {
	const apolloClient = useApollo(props.initialApolloState);

	return (
		<ApolloProvider client={apolloClient}>
			<AuthProvider>
				<Router>
					<AppContent />
				</Router>
			</AuthProvider>
		</ApolloProvider>
	);
}

function AppContent() {
	const [isLoading, setIsLoading] = useState(false);
	const location = useLocation();

	useEffect(() => {
		setIsLoading(true);
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 500);
		return () => clearTimeout(timer);
	}, [location.pathname]);

	return (
		<>
			{isLoading && <Loading />}
			<Switch>
				<Route path="/menu/:id" component={Menu} />
				<Route path="/login" component={Login} />
				<Route path="/signup" component={Signup} />
				<Route path="/signup" component={Maintenance} />
				<PrivateRoute path="/confirm/:id" component={Admin} />
				<PrivateRoute path="/forgot-password" component={Admin} />
				<PrivateRoute path="/recover-password/:id" component={Admin} />
				<PrivateRoute exact path="/admin" component={Admin} />
				<PrivateRoute exact path="/admin" component={Maintenance} />
				<PrivateRoute path="/admin/menu/:id" component={AdminMenu} />
				<PrivateRoute path="/admin/tab/:id" component={AdminSubTab} />
				<PrivateRoute path="/admin/detail/:id" component={AdminDetail} />
				<PrivateRoute path="/data/orders" component={Orders} />
				<PrivateRoute path="/data/menus" component={Menus} />
				<Route path="/" component={LandingPage} />
				<Redirect to="/admin" />
			</Switch>
			<ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover={false}
			/>
		</>
	);
}

export default App;
