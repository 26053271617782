import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import "./SelectItemContent.scss";

class SelectItemContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            list: props.data.list,
            show_english: props.data.show_english,
            errors: { name: { pt: '', en: '' }, description: { pt: '', en: '' }, price: '' }
        };

        this.onComplete = props.onComplete;
        this.onAddItem = props.data.onAddItem;
        this.onPickItem = props.data.onPickItem;
        this.addItem = this.addItem.bind(this);
        this.pickItem = this.pickItem.bind(this);
    }

    addItem(){
        this.onAddItem();
    }
    
    pickItem(){
        this.onPickItem();
    }

    render() {
        const { t } = this.props;

        return (
            <div className={"select-item"}>
                <h2>{t('admin_select_product.title', 'Adicionar item')}</h2>
                <form autoComplete="off">
                    <div className="row">
                        <div className="btn-primary" onClick={this.pickItem}>
                            <span className="icon-drinks"></span>
                            <span className="label">{t('btn.sb_product', 'Produto<br/>Super Bock')}</span>
                            
                        </div>
                        <div className="btn-primary" onClick={this.addItem}>
                            <span className="icon-arrow-down"></span>
                            {t('btn.insert_product', 'Carregar produto')}</div>
                    </div>
                </form>
            </div>
        )
    }
}

export default withTranslation()(SelectItemContent);