import React, { useEffect, useState } from "react";

import "./AddMenuContent.scss";

import { withTranslation } from "react-i18next";

import api from "../../../services";

import RadioGroup from "../partials/RadioGroup";

import { toast } from "react-toastify";

function EditMenuContent(props) {
	const { t } = props;

	const [state, setState] = useState({
		name: "",
		logo: false,
		restaurant: "",
		loading: true,
		visible: false,
		uploading: false,
		english: "",
		shortcut: "",
		errors: {},
	});

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		const menu = await api.menus.getById(props.data.id);

		setState({
			...state,
			...menu,
			visible: menu.visible
				? t("common.txt_yes", "Sim")
				: t("common.txt_no", "Não"),
			shortcut: menu.show_shortcut
				? t("common.txt_yes", "Sim")
				: t("common.txt_no", "Não"),
			english: menu.show_english
				? t("common.txt_yes", "Sim")
				: t("common.txt_no", "Não"),
			show_menu: menu.show_menu
				? t("common.txt_yes", "Sim")
				: t("common.txt_no", "Não"),
			loading: false,
		});
	};

	const validate = () => {
		let errors = {};
		let { name, restaurant } = state;

		const isValidName = name.length == 0 ? null : true;
		errors.name = isValidName
			? null
			: toast.error(
					t("error_msg.menu_name", "O nome do menu não é válido!")
			  );

		const isValidRestaurant = restaurant.length == 0 ? null : true;
		errors.restaurant = isValidRestaurant
			? null
			: toast.error(
					t(
						"error_msg.restaurant_name",
						"O nome do restaurante não é válido!"
					)
			  );

		setState({ ...state, errors });

		return isValidName && isValidRestaurant;
	};

	const handleFocus = (event) => {
		const { name } = event.target;
		const { errors } = state;
		errors[name] = "";
		setState({ ...state, errors });
	};

	const handleChange = (event) => {
		const { name, value } = event.target;

		setState({
			...state,
			[name]: value.replace('"', ""),
		});
	};

	const handleToggle = (event) => {
		setState({
			...state,
			[event.id]: event.selected,
		});
	};

	const onFileChanged = (event) => {
		const { files } = event.target;
		const file = files[0];
		const url =
			process.env.REACT_APP_NOT_SECRET_CODE +
			"/amazon?file-name=" +
			file.name +
			"&file-type=" +
			file.type;

		setState({ ...state, uploading: true });

		fetch(url, {
			method: "GET",
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (result) {
				const xhr = new XMLHttpRequest();
				xhr.open("PUT", result.signedRequest, true);
				xhr.onload = function () {
					if (
						this.readyState === XMLHttpRequest.DONE &&
						this.status === 200
					) {
						setState({
							...state,
							uploading: false,
							logo: result.url,
						});
					}
				};

				xhr.send(file);
			});
	};

	const removeImage = (event) => {
		event.preventDefault();
		setState({ ...state, logo: "" });
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!validate()) return;

		setState({ ...state, loading: true });

		const show_english = state.english === t("common.txt_yes", "Sim");
		const show_shortcut = state.shortcut === t("common.txt_yes", "Sim");
		const visible = state.visible === t("common.txt_yes", "Sim");

		const { logo, name, restaurant, id } = state;

		const update = await api.menus.update(props.data.id, {
			name,
			restaurant,
			logo,
			show_english,
			show_shortcut,
			visible,
		});

		if (update && update.id) {
			props.onComplete();
		} else {
			toast.error("Erro ao atualizar o menu.");
			console.log("Error updating menu");
		}
	};

	const { errors, uploading, logo, loading } = state;

	return (
		<div className={loading ? "edit-menu loading" : "edit-menu"}>
			<h2>{t("admin_edit_menu.title", "Editar menu")}</h2>
			<form autoComplete="off">
				<input
					type="text"
					placeholder={t("placeholder.menu_name", "Nome do menu")}
					name="name"
					value={state.name}
					onChange={handleChange}
					onFocus={handleFocus}
				/>

				<input
					type="text"
					placeholder={t(
						"placeholder.restaurant_name",
						"Nome do estabelecimento"
					)}
					name="restaurant"
					value={state.restaurant}
					onChange={handleChange}
					className="m-top-4"
				/>

				<label
					htmlFor="logo"
					className={
						uploading
							? "button button-upload-image upload m-top-4"
							: "button button-upload-image m-top-4"
					}
				>
					<span>
						{logo
							? "logo.png"
							: t("btn.upload_logo", "Carregar logótipo")}
					</span>
					<span>
						{logo
							? t("btn.upload_replace", "clique para substituir")
							: t("common.txt_optional", "(opcional)")}
					</span>
					{logo ? (
						<a href="#" className="remove" onClick={removeImage}>
							<img src="/images/admin/icons/delete.png" />
						</a>
					) : null}
				</label>
				<input
					type="file"
					id="logo"
					onChange={onFileChanged}
					accept="image/x-png,image/gif,image/jpeg"
				/>
			</form>
			<div
				className="row m-top-4"
				style={!state.english ? { display: "none" } : null}
			>
				<div className="label">
					{process.env.REACT_APP_VERSION === "sb:user:en" ? (
						<h2>
							{t(
								"placeholder.portuguese_version",
								"Versão em *Português"
							)}
						</h2>
					) : (
						<h2>
							{t(
								"placeholder.english_version",
								"Versão em Inglês"
							)}
						</h2>
					)}
				</div>
				<RadioGroup
					id="english"
					name="number"
					options={[
						t("common.txt_yes", "Sim"),
						t("common.txt_no", "Não"),
					]}
					selected={state.english}
					onChange={handleToggle}
				/>
			</div>
			<div className="row m-top-4">
				<div className="label">
					<h2>
						{t(
							"placeholder.drinks_shortcut",
							"Atalho para bebidas"
						)}
					</h2>
				</div>
				<RadioGroup
					id="shortcut"
					name="number"
					options={[
						t("common.txt_yes", "Sim"),
						t("common.txt_no", "Não"),
					]}
					selected={state.shortcut}
					onChange={handleToggle}
				/>
			</div>
			<div className="row m-top-4">
				<div className="label">
					<h2>{t("placeholder.public_menu", "Menu visível")}</h2>
				</div>
				<RadioGroup
					id="visible"
					name="number"
					options={[
						t("common.txt_yes", "Sim"),
						t("common.txt_no", "Não"),
					]}
					selected={state.visible}
					onChange={handleToggle}
				/>
			</div>
			<div className="m-top-8">
				<div onClick={handleSubmit} className="btn-primary">
					{t("btn.save", "Guardar")}
				</div>
			</div>
		</div>
	);
}

export default withTranslation()(EditMenuContent);
