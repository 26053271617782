import React from 'react';
import Carousel from "@itseasy21/react-elastic-carousel";
import '../menu/MenuList.scss';

const ITEMS_PER_PAGE = 50;

const MenuList = ({ data, language, style, onSelected, onSubList, tab, tabdetail }) => {

  const onListClick = (e) => {
    e.preventDefault();

    const { currentTarget } = e;
    onSelected(currentTarget.dataset.id);
  };

  const onTabClick = (e) => {
    e.preventDefault();
    const { currentTarget } = e;
    onSubList(currentTarget.dataset.id);
  }

  const chunkArray = (myArray, chunk_size) => {
    var results = [],
      tmpArray = myArray.concat();

    while (tmpArray.length) {
      results.push(tmpArray.splice(0, chunk_size));
    }
    return results;
  }

  const sortByOrder = (items) => {
    items.sort(function (a, b) {
      if (a.order === null) {
        let max = items.length;
        if (a.order > items.length || b.order > items.length) max = (a.order < b.order ? b.order : a.order) + 1;
        a.order = max;
      }
      return a.order - b.order;
    });
    return items;
  }

  const insertDefaultTab = (arr, index, newItem) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index)
  ]

  const render = () => {
    if (data) {
      let items;

      if (tabdetail && tabdetail.tabs) {
        items = sortByOrder(tabdetail.tabs);
      } else items = sortByOrder(data.lists);

      if (!data.tablists) {
        const mainItems = items.filter((tab, index) => {
          if (!tab.parent) {
            return tab;
          }
        });
        let defaultTabIndex;
        const defaultTab = mainItems.filter((tab, index) => {
          if (tab.default) {
            defaultTabIndex = index;
            return tab;
          }
        });
        if (defaultTab.length) {
          mainItems.splice(defaultTabIndex, 1); //remove previous default tab 
          mainItems.splice(mainItems.length < 7 ? 1 : 2, 0, defaultTab[0]); //add default
          items = mainItems;
        }
      }
      
      const pages = chunkArray(items, ITEMS_PER_PAGE);

      const categoryBtn = (
        <li key={tabdetail ? tabdetail.id : null}>
          <a className="item" href="#" data-id={tabdetail ? tabdetail.id : null} onClick={onListClick} style={style ? style.buttons.background : {}}>
            <div className="button">
              <span style={style ? style.text : {}}> {tabdetail ? tabdetail['name_' + language] : ''} </span>
            </div>
          </a>
        </li>
      )
      let pagesHtml = pages.map((page, index) => {
        return (
          <ul key={index} className={items.length < 8 ? 'icons' : 'icons col-3'}>
            {tabdetail && tabdetail.items.length > 0 ? categoryBtn : null}
            {page.map(list => {
              if (list.parent && !tab || list.hide) return;
              return (
                <li key={list.id}>
                  <a className="item" href="#" data-id={list.id} onClick={list.subtab ? onTabClick : onListClick} style={style ? style.buttons.background : {}}>
                    <div className="button">
                      {
                        list.icon && list.icon.image ?
                          <img className="icon" src={'/images/icons/' + list.icon.image + '.svg'} alt="" style={{ filter: style && style.isDarkMode ? 'invert(0.7)' : 'invert(0)' }} /> : null
                      }
                      <span style={style ? style.text : {}}>{list['name_' + language]}</span>
                    </div>
                  </a>
                </li>
              )
            })}
          </ul>
        )
      });

      if (tabdetail && pagesHtml.length === 0 && tabdetail.items.length > 0) pagesHtml = (<ul className='icons'>{categoryBtn}</ul>);

      return (
        <div className="tabs">
          <Carousel showArrows={false} pagination={pages.length > 1 ? true : false} enableSwipe={true} enableMouseSwipe={true}>{pagesHtml}</Carousel>
        </div>
      );
    } else {
      return (
        <div className="loader">
          <img src="/images/loader.gif" alt="" />
        </div>
      );
    }
  }

  return render();
}

export default MenuList;
