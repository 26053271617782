import React from 'react';
import './popups/Popup.scss';

const ErrorPopup = ({ message, onClose }) => {

  const render = () => {
    if (message) {
      return (
        <div className="popup">
          <div className="box">
            <div className="delete">
              <h2>{message}</h2>
            </div>
            <a href="#" className="close" onClick={onClose}>
                <span className="icon-close"></span>
            </a>
          </div>
        </div>
      );
    } else return (null);
  }

  return render();
}

export default ErrorPopup;
