import React, { Component, useState } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { withTranslation } from 'react-i18next';
import "./QRCodeContent.scss";

function QRCodeContent(props) {
    const { t } = props;

    const handlePrint = e => {
        e.preventDefault();
        window.print();
    }

    return (
        <div className="qr-code">
            <h2>{t('admin_qrcode.title', 'QR Code')}</h2>
            <figure>
                <QRCode value={props.data.url} fgColor="#000" size="300" logoWidth="82" logoImage="/images/logo-superbock-bw.png" />
            </figure>
            <div className="share-button">
                <a href={"whatsapp://send?text=" + props.data.url}>
                    <span>{t('btn.share', 'Partilhar')}</span>
                </a>
            </div>
            <div className="m-top-8">
                <a onClick={handlePrint} className="btn-primary">
                    {t('btn.print', 'Imprimir')}
                </a>
            </div>
        </div>
    )
}

export default withTranslation()(QRCodeContent);