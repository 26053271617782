// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/images/admin/icons/drag.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-tab-item {
  background: #f5f5f5 url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat 95% center;
  background-size: 8%;
  border: 0;
  border-radius: 6px;
  color: #5c5c5c;
  cursor: pointer;
  outline: none;
  margin-bottom: 4%;
  box-sizing: border-box;
  font: 1.6em/1 vigaregular;
  padding: 20px 15px;
  width: 100%;
  z-index: 20;
}

.popup .sort-tabs {
  padding: 40px 20px 20px 20px;
}
.popup .sort-tabs h2 {
  font: 2em/1 vigaregular;
  margin-bottom: 10px;
  color: #5c5c5c;
}
.popup .sort-tabs .list-tabs {
  margin: 4% 0 2% 0;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/popups/SortContent.scss"],"names":[],"mappings":"AAEA;EACI,gFAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,aAAA;EACA,iBAAA;EACA,sBAAA;EACA,yBAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;AADJ;;AAKI;EACI,4BAAA;AAFR;AAIQ;EACI,uBAAA;EACA,mBAAA;EACA,cAAA;AAFZ;AAKQ;EACI,iBAAA;AAHZ","sourcesContent":["\n//todo - show styles on drag\n.list-tab-item{\n    background: #f5f5f5 url(../../../../public/images/admin/icons/drag.png) no-repeat 95% center;\n    background-size: 8%;\n    border: 0;\n    border-radius: 6px;\n    color: #5c5c5c;\n    cursor: pointer;\n    outline: none;\n    margin-bottom: 4%;\n    box-sizing: border-box;\n    font: 1.6em/1 vigaregular;\n    padding: 20px 15px;\n    width: 100%;\n    z-index: 20;\n}\n\n.popup {\n    .sort-tabs {\n        padding: 40px 20px 20px 20px;\n\n        h2 {\n            font: 2em/1 vigaregular;\n            margin-bottom: 10px;\n            color: #5c5c5c;\n        }\n\n        .list-tabs{\n            margin: 4% 0 2% 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
