import React, { useState } from "react";

import { withTranslation } from "react-i18next";
import RadioGroup from "../partials/RadioGroup";

import api from "../../../services";

import "./AddItemContent.scss";

import { toast } from "react-toastify";

function AddItemContent(props) {
	const { t } = props;

	const [state, setState] = useState({
		area: "edit",
		loading: false,
		uploading: false,
		list: props.data.list,
		show_english: props.data.show_english,
		language: process.env.REACT_APP_VERSION === "sb:user:en" ? "en" : "pt",
		description_pt: "",
		description_en: "",
		name_pt: "",
		name_en: "",
		items: [],
		price: "",
		image: "",
	});

	const validate = () => {
		const {
			name_pt,
			name_en,
			// description_pt,
			// description_en,
			price,
			show_english,
			items,
		} = state;

		if (items.length > 0) state.price = 0;

		let isValidNamePT = true;
		if (process.env.REACT_APP_VERSION === "sb:user" || show_english) {
			isValidNamePT = name_pt.length == 0 ? null : true;
			if (!isValidNamePT) toast.error(t("Nome do produto inválido!"));
		}

		let isValidNameEN = true;
		if (show_english) {
			isValidNameEN = name_en.length === 0 ? null : true;
			if (!isValidNameEN)
				toast.error(t("Nome do produto em Inglês não preenchido!"));
		}

		let isValidPrice = true;
		if (!price || price.length == 0) {
			isValidPrice = false;
			toast.error(t("error_msg.value", "Valor inválido!"));
		}

		// let isValidDescriptionPT = true;
		// if (process.env.REACT_APP_VERSION != "sb:user:en") {
		// 	isValidDescriptionPT = description_pt.length === 0 ? null : true;
		// 	if (!isValidDescriptionPT)
		// 		toast.error(t("Descrição do produto inválido!"));
		// }

		// let isValidDescriptionEN = true;
		// if (show_english) {
		// 	isValidDescriptionEN = description_en.length === 0 ? null : true;
		// 	if (!isValidDescriptionEN)
		// 		toast.error(t("Descrição do produto em Inglês não preenchido!"));
		// }

		return (
			isValidNamePT 
			&& isValidNameEN 
			&& isValidPrice 
			// && isValidDescriptionPT 
			// && isValidDescriptionEN
		);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!validate()) return;

		setState({ ...state, loading: true });

		const item = await api.items.add({
			name_pt: state.name_pt,
			name_en: state.name_en,
			description_pt: state.description_pt,
			description_en: state.description_en,
			list: { connect: { id: state.list } },
			price: Number(state.price),
			image: state.image,
			visible: true,
		});

		if (item && item.id) {
			if (state.items.length) {
				let data = [],
					variant;

				for (let i = 0; i < state.items.length; i++) {
					variant = state.items[i];
					data.push({
						name_pt: variant.name_pt,
						name_en: variant.name_en,
						price: Number(variant.price),
						item: { connect: { id: item.id } },
					});
				}

				const items = await api.subitems.addMany(data);
				if (items) props.onComplete();
			} else props.onComplete();
		}
	};

	const handleChange = (event) => {
		let { name, value } = event.target;
		if (name == "price" && value) {
			value = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
		}

		setState({ ...state, [name]: value });
	};

	const handleLanguage = (event) => {
		const { language } = state;
		const { name, value } = event.target;
		setState({ ...state, [name + "_" + language]: value.replace('"', "") });
	};

	const handleLanguageChange = (data) => {
		setState({ ...state, language: data.selected });
	};

	const onFileChanged = (event) => {
		const { files } = event.target;
		const file = files[0];
		const url =
			process.env.REACT_APP_NOT_SECRET_CODE +
			"/amazon?filename=" +
			file.name +
			"&filetype=" +
			file.type;

		setState({ ...state, uploading: true });

		fetch(url, {
			method: "GET",
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (result) {
				const xhr = new XMLHttpRequest();
				xhr.open("PUT", result.signedRequest, true);
				xhr.onload = function () {
					if (
						this.readyState === XMLHttpRequest.DONE &&
						this.status === 200
					) {
						setState({
							...state,
							uploading: false,
							image: result.url,
						});
					}
				};

				xhr.send(file);
			});
	};

	const removeImage = (event) => {
		event.preventDefault();
		setState({ ...state, image: "" });
	};

	const handleItems = (event) => {
		setState({ ...state, area: "items" });
	};

	const deleteItem = (event) => {
		event.preventDefault();

		const { items } = state;

		const { currentTarget } = event;
		const index = currentTarget.dataset.id;

		items.splice(index, 1);

		setState({ ...state, items });
	};

	const addItem = (event) => {
		event.preventDefault();

		const { items } = state;

		items.push({ name_pt: "", name_en: "", price: 0 });

		setState({ ...state, items });
	};

	const handleItemChange = (event) => {
		let { name, value } = event.target;
		const { parentNode } = event.target;
		const { items, language } = state;

		if (name == "price" && value) {
			value = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
		}

		const i = parentNode.id;

		switch (name) {
			case "name":
				items[i]["name_" + language] = value;
				break;
			case "price":
				items[i].price = value;
				break;
		}

		setState({ ...state, items: items });
	};

	const handleBack = (event) => {
		setState({ ...state, area: "edit" });
	};

	const renderItems = () => {
		const { show_english, loading, items, language } = state;

		const onDelete = (e) => deleteItem(e);
		const onChange = (e) => handleItemChange(e);

		return (
			<div className={loading ? "add-variant loading" : "add-variant"}>
				<h2>
					{process.env.REACT_APP_VERSION !== "sb:user:en"
						? t(
								"admin_edit_product.txt_variant_of",
								"Variantes de "
						  ) + state.name_pt
						: t(
								"admin_edit_product.txt_variant_of",
								"Variantes de "
						  ) + state.name_en}
				</h2>
				<form autoComplete="off">
					{items.length
						? items.map(function (item, index) {
								return (
									<div className="variant-item" key={index}>
										<div className="row m-top-4" id={index}>
											<input
												type="text"
												placeholder={t(
													"placeholder.name",
													"Nome"
												)}
												name="name"
												value={item["name_" + language]}
												onChange={onChange}
											/>
											<input
												type="text"
												placeholder="0.00"
												name="price"
												maxLength="8"
												value={item.price}
												onChange={onChange}
											/>
											<div className="del-variant">
												<div
													href="#"
													data-id={item.id}
													onClick={onDelete}
												>
													<span className="icon-trash"></span>
												</div>
											</div>
										</div>
									</div>
								);
						  })
						: null}
					{items.length < 5 ? (
						<div className="m-top-8">
							<a
								href="#"
								className="variant-button"
								onClick={addItem}
							>
								<span>
									{t(
										"btn.add_variant",
										"Adicionar variante de produto"
									)}
								</span>
								<span className="icon-add"></span>
							</a>
						</div>
					) : null}
					{items.length ? (
						<div className="row m-top-4">
							{show_english ||
							process.env.REACT_APP_VERSION === "sb:user:en" ? (
								<RadioGroup
									id="language"
									name="language"
									options={["pt", "en"]}
									selected={state.language}
									onChange={handleLanguageChange}
								/>
							) : null}
						</div>
					) : null}
					<div className="m-top-4">
						<a
							href="#"
							onClick={handleBack}
							className="btn-primary"
						>
							{items.length
								? t("btn.save", "Guardar")
								: t("btn.back", "Voltar")}
						</a>
					</div>
				</form>
			</div>
		);
	};

	const renderEdit = () => {
		const { show_english, language, loading, uploading, image } = state;

		return (
			<div className={loading ? "add-item loading" : "add-item"}>
				<h2>{t("admin_add_product.title", "Adicionar produto")}</h2>
				<form autoComplete="off">
					<div className="fields">
						<input
							type="text"
							placeholder={t("placeholder.name", "Nome")}
							name="name"
							onChange={handleLanguage}
							value={state["name_" + language]}
						/>

						<textarea
							type="text"
							placeholder={t(
								"placeholder.description",
								"Descrição"
							)}
							name="description"
							onChange={handleLanguage}
							value={state["description_" + language]}
						/>
					</div>
					<div className="row m-top-4">
						<input
							type="text"
							placeholder="0.00"
							name="price"
							onChange={handleChange}
							value={state.price}
						/>
						{(show_english &&
							process.env.REACT_APP_VERSION === "sb:user") ||
						show_english === true ? (
							<RadioGroup
								id="language"
								name="language"
								options={["pt", "en"]}
								selected={state.language}
								onChange={handleLanguageChange}
							/>
						) : null}
					</div>
					<label
						htmlFor="logo"
						className={
							uploading
								? "button button-upload-image upload m-top-4"
								: "button button-upload-image m-top-4"
						}
					>
						<span>
							{image
								? t("btn.image", "imagem.jpg")
								: t(
										"btn.upload_product_img",
										"Carregar imagem do produto"
								  )}
						</span>
						<span>
							{image
								? t(
										"btn.upload_replace",
										"clique para substituir"
								  )
								: t("common.txt_optional", "(opcional)")}
						</span>
						{image ? (
							<a
								href="#"
								className="remove"
								onClick={removeImage}
							>
								<span className="icon-trash"></span>
							</a>
						) : null}
					</label>
					<input
						type="file"
						id="logo"
						onChange={onFileChanged}
						accept="image/x-png,image/gif,image/jpeg"
					/>
				</form>
				<div className="m-top-4">
					<a
						href="#"
						className="variant-button"
						onClick={handleItems}
					>
						<span>
							{t(
								"btn.add_variant",
								"Adicionar variante de produto"
							)}
						</span>
						<span className="icon-edit-bold"></span>
					</a>
				</div>
				{/*
                <div className="m-top-4">
                    <a href="#" className="delete-button" onClick={this.onDeleteMainItem}>
                        <span>{t('btn.delete_product', 'Apagar produto')}</span>
                        <span className="icon-trash"></span>
                    </a>
                </div>
                */}
				<div className="m-top-4">
					<a href="#" onClick={handleSubmit} className="btn-primary">
						{t("btn.save", "Guardar")}
					</a>
				</div>
			</div>
		);
	};

	const { area } = state;

	switch (area) {
		case "edit":
			return renderEdit();
		case "items":
			return renderItems();
		default:
			return null;
	}
}

export default withTranslation()(AddItemContent);
