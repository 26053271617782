import React from 'react';
import './Stepper.scss';

const SpecialStepper = ({ id, value, active, max, onValueChange, style }) => {
    const increment = () => {
        if(!active) return;

        if(value < max) value += 1;
        onValueChange(id, value);
    }

    const decrement = () => {
        if(!active) return;

        if(value > 0) value -= 1;
        onValueChange( id, value);
    }

    const render = () => {
        return (
            <div className={active ? "stepper active" : "stepper"}>
                <button className="minus" onClick={decrement} style={style ? style.buttons.tertiary : {}} />
                <span style={style ? style.buttons.color : {}}>{value}</span>
                <button className="plus" onClick={increment} style={style ? style.buttons.primary : {}}/>
            </div >
        );
    }

    return render();
}

export default SpecialStepper;
