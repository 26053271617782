import React, { useState } from "react";

import { withTranslation } from "react-i18next";

import { useAuth } from "../../../lib/auth";

import "./AddTabContent.scss";
import { toast } from "react-toastify";

function EditUserContent(props) {
	const { t } = props;

	const { user, update, signout } = useAuth();

	const [state, setState] = useState({
		loading: !user,
		code: user && user.code,
		name: user && user.name,
		nif: user && user.nif,
	});

	const validate = () => {
		let { name, nif, code } = state;

		const isValidName = name.length < 3 ? null : true;
		if (!isValidName)
			toast.error(
				t("error_msg.company_name", "Nome de empresa inválido!")
			);

		let isValidNif = true,
			isValidCode = true;

		if (process.env.REACT_APP_VERSION != "sb:user:en") {
			isValidNif = !/^\d+$/.test(nif) || nif.length !== 9 ? null : true;
			if (!isValidNif)
				toast.error(t("error_msg.vat_number", "NIF inválido!"));

			isValidCode =
				!/^\d+$/.test(code) || code.length !== 10 ? null : true;
			if (!isValidCode) {
				toast.error(t("error_msg.client_code", "Código inválido!"));
			}
		}

		return isValidName && isValidNif && isValidCode;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!validate()) return;

		setState({ ...state, loading: true });

		const { name, nif, code } = state;

		const result = await update({
			id: user.id,
			data: { name, nif, code },
		});

		console.log(result);

		if (result && result.updateUser) props.onComplete();
		else setState({ ...state, loading: false });
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setState({ ...state, [name]: value.replace('"', "") });
	};

	const deleteUser = (e) => {
		e.preventDefault();
		props.data.onDelete();
	};

	const onLogOut = (e) => {
		e.preventDefault();
		signout().then((result) => {
			window.location.reload();
		});
	};

	const { loading } = state;

	return (
		<div className={loading ? "add-menu loading" : "add-menu"}>
			<h2>{t("admin_edit_account.title", "Editar conta")}</h2>
			<form autoComplete="off">
				<input
					type="text"
					placeholder={t(
						"placeholder.company_name",
						"Nome da Empresa"
					)}
					name="name"
					onChange={handleChange}
					value={state.name}
					className=""
				/>
				{process.env.REACT_APP_VERSION != "sb:user:en" ? (
					<input
						type="text"
						placeholder={t("placeholder.vat_number", "NIF")}
						name="nif"
						onChange={handleChange}
						value={state.nif}
						className="m-top-4"
					/>
				) : null}
				{process.env.REACT_APP_VERSION != "sb:user:en" ? (
					<input
						type="text"
						placeholder={t(
							"placeholder.client_code",
							"Código de Cliente"
						)}
						name="code"
						onChange={handleChange}
						value={state.code}
						className="m-top-4"
					/>
				) : null}
			</form>
			<div className="m-top-8">
				<div onClick={handleSubmit} className="btn-primary">
					{t("btn.save", "Guardar")}
				</div>
			</div>
			<div className="m-top-4">
				<div onClick={deleteUser} className="btn-tertiary">
					{t("btn.delete_account", "Apagar Conta")}
				</div>
			</div>
			<div className="m-top-4">
				<div onClick={onLogOut} className="btn-tertiary">
					{t("btn.logout", "Logout")}
				</div>
			</div>
			<div className="m-top-8">
				<div className="txt-center">
					<a
						href={t(
							"admin_signup.url_privacy_policy",
							"/pdf/politica-de-privacidade.pdf"
						)}
						target="_blank"
						className="link"
						rel="noreferrer"
					>
						{t(
							"admin_signup.label_privacy_policy",
							"Política de privacidade"
						)}
					</a>
				</div>
				<div className="txt-center m-top-4">
					<a
						href={t(
							"admin_signup.url_terms_and_conditions",
							"/pdf/termos-e-condicoes.pdf"
						)}
						target="_blank"
						className="link"
						rel="noreferrer"
					>
						{t(
							"admin_signup.label_terms_and_condtions",
							"Termos e Condições"
						)}
					</a>
				</div>
			</div>
		</div>
	);
}

export default withTranslation()(EditUserContent);
