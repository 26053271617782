import React, { useEffect, useState } from "react";

import { withTranslation } from 'react-i18next';

import Styles from "../utils/styles";
import Popup from "./popups/Popup";
import Tabs from "./partials/Tabs";
import api from "../../services";

import "./AdminMenu.scss";

function AdminMenu(props) {
  const { t } = props;

  const id = props.match.params.id;

  const [state, setState] = useState({
    popup: null,
    data: null
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const menu = await api.menus.getById(id);
    setState({ ...state, menu, popup: null });
  }

  const editMenu = e => {
    e.preventDefault();
    setState({
      ...state,
      popup: { name: "edit_menu", id }
    });
  }

  const addTab = e => {
    e.preventDefault();
    const show_english = state.menu && state.menu.show_english;
    setState({
      ...state,
      popup: { name: "add_tab", menu: state.menu, show_english }
    });
  }

  const onTheme = e => {
    e.preventDefault();
    setState({
      ...state,
      popup: { name: 'edit_theme', menu: state.menu, onReset: onResetTheme }
    });
  }

  const onResetTheme = data => {
    setState({
      ...state,
      popup: { name: 'reset_theme', settings: data }
    });
  }

  const editTab = e => {
    e.preventDefault();
    const { currentTarget } = e;
    const show_english = state.menu && state.menu.show_english;

    setState({
      ...state,
      popup: {
        name: "edit_tab",
        id: currentTarget.dataset.id,
        show_english,
        onDelete: confirmDelete,
      },
    });
  }

  const sortTabs = list => {
    setState({
      ...state,
      popup: { name: "sort_tabs", list: list, onSave: closePopup }
    });
  }

  const orderMenu = e => {
    e.preventDefault();
    setState({ ...state, popup: { onPublished: showQRCode, name: "publish_menu", id } });
  }

  const publishMenu = async e => {
    e.preventDefault();
    const menu = await api.menus.update(id, { published: true });
    if (menu && menu.id) getData();
  }

  const showQRCode = () => {
    const url = state.menu.url;
    const image = state.menu.qrcode;
    setState({ ...state, popup: { name: "qr_code", image, url } });
  }

  const closePopup = e => {
    if (e) e.preventDefault(); getData();
  }

  const confirmDelete = id => {
    setState({
      ...state,
      popup: { ...state, name: "delete", id, onDelete: deleteTab },
    });
  }

  const deleteTab = async id => {
    const result = await api.itemlist.delete(id);

    if (result && result.id) closePopup();
    else console.log("Error deleting ItemList");
  }

  const checkLogo = (menu, style) => {
    if (menu.logo && menu.logo !== "null") {
      return <img className="logo" src={menu.logo} alt={menu.restaurant} />;
    } else {
      return <h1 style={style.text}>{menu.restaurant}</h1>;
    }
  }

  const checkPublished = (menu, publish, order_materials, style) => {
    if (!menu.published || !menu.order.length) {
      return (
        <div className="footer-buttons m-top-4">
          {!menu.order.length && !(process.env.REACT_APP_VERSION === "sb:user:en") ?
            <div onClick={orderMenu} className="btn-secundary" style={style.border}>
              <span>{order_materials}</span>
            </div>
            :
            <div onClick={publishMenu} className="btn-secundary" style={style.border}>
              <span>{publish}</span>
            </div>
          }

        </div>
      );
    }
  }

  const parseLists = lists => {
    return lists.filter((item, index) => {
      if (!item.parent) return item;
    });
  }

  const { menu, popup } = state;

  if (menu) {
    const style = Styles.checkStyle(menu);

    return (
      <div className="menu" style={style.background.backgroundColor || style.background.backgroundImage ? { backgroundColor: '#eee' } : null}>
        <div className="app opened" style={style.background}>
          <header className="main-header">
            <div className="top-header">
              <div className="button">
                <a className="back" href="/admin">
                  <span className="icon-arrow-back" style={style ? style.customText : {}}></span>
                </a>
              </div>

              <div className="button theme" onClick={onTheme} style={style ? style.buttons.background : {}}>
                <span className="icon-painting" style={style.text}></span>
              </div>
            </div>

            <div className="cover">
              <div className="image" style={style.cover}></div>
              <div className="product" style={style.backgroundProduct}></div>
            </div>

            <div className="placeholder editable" style={{ borderColor: style.isDarkMode ? 'rgba(92, 92, 92, 0.3)' : 'rgba(255, 255, 255, 0.3)' }}>
              {checkLogo(menu, style)}
              <div className="edit" onClick={editMenu}>
                <span className="icon-edit" style={style.customText}></span>
              </div>
            </div>
          </header>

          <Tabs menu={id} items={parseLists(menu.lists)} style={style} addTab={addTab} editTab={editTab} sortTabs={sortTabs} subTab={false}></Tabs>

          {checkPublished(menu, t('btn.publish', 'Publicar'), t('btn.order_materials', 'Encomendar Materiais'), style.buttons)}

          <div className="m-bottom-10"></div>

          <Popup data={popup} onClose={closePopup} style={style} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="loader">
        <img src="/images/loader.gif" alt="" />
      </div>
    );
  }
}

export default withTranslation()(AdminMenu);
