import React, { Component, useEffect, useRef, useState } from 'react';

import { withTranslation } from 'react-i18next';

import Carousel from "@itseasy21/react-elastic-carousel";

import "./PickItemContent.scss";
import api from '../../../services';

function PickItemContent(props) {
    const { t } = props;

    const carousel = useRef(null);

    const [state, setState] = useState({
        loading: true,
        list: props.data.list,
        show_english: props.data.show_english,
        data: null
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const products = await api.products.getAll();
        setState({ ...state, loading: false, data: products });
    }

    const onSelected = async () => {
        const product = state.data[carousel.current.state.activePage];

        setState({ ...state, loading: true });

        const item = await api.items.add({
            name_pt: product.name_pt,
            name_en: product.name_en,
            description_pt: product.description_pt,
            description_en: product.description_en,
            list: { connect: { id: state.list } },
            image: product.photo,
            visible: true
        });

        if(item && item.id) {
            props.onComplete({ popup: 'pick', item });
        }else console.log('Error creating Item');

        // const { services } = this.context;
        // const name = `{ create: { pt: "${product.name.pt}", en: "${product.name.en ? product.name.en : ''}" } }`;
        // const description = `{ create: { pt: "${product.description && product.description.pt ? product.description.pt : ''}", en: "${product.description && product.description.en ? product.description.en : ''}" } }`;
        // const image = product.image ? product.image.publicUrl : null;
        // const data = `{ name: ${name}, description: ${description}, price: 0, lists: { connect: { id: "${this.state.list}" } }, image: "${image}" }`;

        // services.itemsApi.add(data)
        //     .then(result => {
        //         if (result && result.createItem) {
        //             this.onComplete({ popup: 'pick', item: result.createItem });
        //         } else console.log(result.errors);
        //     });

    }

    const { loading, data } = state;

    let productsHtml = null;
    
    if (data) {
        productsHtml = data.map((product, index) => {
            return (
                <div className="item-product" id={product.id} key={product.id}>
                    <figure className="m-top-4">
                        {product.photo ? <img src={product.photo} alt={product.name_pt} /> : <img src="/images/thumb-item.jpg" />}
                    </figure>
                    <div className="m-top-8">
                        <span>{product.name_pt}</span>
                    </div>
                </div>
            )
        })
    }
    return (
        <div className="pick-item">
            <h2>{t('admin_pick_product.title', 'Produto Super Bock Group')}</h2>
            {!loading ?
                <div className="m-top-4">
                    <div className="">
                        <Carousel ref={carousel} showArrows={true} pagination={true} enableSwipe={true} enableMouseSwipe={true} >
                            {productsHtml}
                        </Carousel>
                        <div className="m-top-4">
                            <div className="btn-primary" onClick={() => onSelected()}>{t('btn.select', 'Seleccionar')}</div>
                        </div>
                    </div>
                </div>
                :
                <div className="loader">
                    <img src="/images/loader.gif" alt="" />
                </div>
            }
        </div>
    )
}

export default withTranslation()(PickItemContent);