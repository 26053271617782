import { gql } from "@apollo/client";
import initializeApollo from "../lib/initApollo";

const PRODUCT_DATA = `{
    id
    name_pt
    name_en
    description_pt
    description_en
    image {
        url
    }
    photo
}`;

const GET_ALL = gql`
    query {
        products ${PRODUCT_DATA}
    }
`;

const apolloClient = initializeApollo();

const services = {
    getAll: async () => {
		const {
			data: { products },
		} = await apolloClient.query({
			query: GET_ALL
		});

		return products;
	},
};

export default services;