import { gql } from "@apollo/client";
import initializeApollo from "../lib/initApollo";

const SUB_ITEM_DATA = `
    id
    name_pt
    name_en
    price
`;

const GET = gql`
  query get($id: ID!) {
    subItems(where: { id: $id }) {
      ${SUB_ITEM_DATA}
    } 
  }
`;

const GET_BY_FILTER = gql`
  query get($filter: SubItemWhereInput!) {
    subItems(where: $filter) {
      ${SUB_ITEM_DATA}
    }
  }
`;

const CREATE_MUTATION = gql`
	mutation create($data: SubItemCreateInput!) {
		createSubItem(data: $data) {
			${SUB_ITEM_DATA}
		}
	}
`;

const CREATE_MANY = gql`
	mutation create($data: [SubItemCreateInput!]!) {
		createSubItems(data: $data) {
			${SUB_ITEM_DATA}
		}
	}
`;

const UPDATE_MUTATION = gql`
	mutation update($id: ID!, $data: SubItemUpdateInput!) {
		updateSubItem(where: { id: $id }, data: $data) {
			id
		}
	}
`;

const UPDATE_MANY = gql`
	mutation update($data: [SubItemUpdateArgs!]!) {
		updateSubItems(data: $data) {
			id
		}
	}
`;

const DELETE_MUTATION = gql`
	mutation delete($id: ID!) {
		deleteSubItem(where: { id: $id }) {
			id
		}
	}
`;

const apolloClient = initializeApollo();

const services = {
    getById: async (id) => {
        const {
            data: { subItem },
        } = await apolloClient.query({
            query: GET,
            variables: { id },
        });

        return subItem;
    },
    getByItem: async (item) => {
        const {
            data: { subItem },
        } = await apolloClient.query({
            query: GET_BY_FILTER,
            variables: { item }
        });

        return subItem;
    },
    add: async (data) => {
        const {
            data: { createSubItem },
        } = await apolloClient.query({
            query: CREATE_MUTATION,
            variables: { data },
        });

        return createSubItem;
    },
    addMany: async data => {
        const {
            data: { createSubItems },
        } = await apolloClient.query({
            query: CREATE_MANY,
            variables: { data }
        });

        return createSubItems;
    },
    update: async (id, data) => {
        const {
            data: { updateItemList },
        } = await apolloClient.query({
            query: UPDATE_MUTATION,
            variables: { id, data }
        });

        return updateItemList;
    },
    updateMany: async data => {
        const {
            data: { updateSubItems },
        } = await apolloClient.query({
            query: UPDATE_MANY,
            variables: { data }
        });

        return updateSubItems;
    },
    delete: async (id) => {
        const {
            data: { deleteSubItem },
        } = await apolloClient.query({
            query: DELETE_MUTATION,
            variables: { id }
        });

        return deleteSubItem;
    }
};

export default services;