import React, { Component, useState } from "react";

import { withTranslation } from "react-i18next";

import RadioGroup from "../partials/RadioGroup";

import { useAuth } from "../../../lib/auth";

import api from "../../../services";

import "./AddMenuContent.scss";

import { toast } from "react-toastify";

function AddMenuContent(props) {
	const { t } = props;

	const { user } = useAuth();

	const [state, setState] = useState({
		name: "",
		restaurant: "",
		english: t("common.txt_no", "Não"),
		shortcut: t("common.txt_yes", "Sim"),
		user: props.data.user,
		loading: false,
		public: false,
	});

	const validate = () => {
		let isValid = true;

		const { name, restaurant } = state;

		if (name.length === 0) {
			toast.error(
				t("error_msg.menu_name", "O nome do menu não é válido!")
			);
			isValid = false;
		}

		if (restaurant.length === 0) {
			toast.error(
				t(
					"error_msg.restaurant_name",
					"O nome do restaurante não é válido!"
				)
			);
			isValid = false;
		}

		return isValid;
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setState({ ...state, [name]: value.replace('"', "") });
	};

	const handleToggle = (event) => {
		setState({ ...state, [event.id]: event.selected });
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!validate()) return;

		setState({ ...state, loading: true });

		const show_english = state.english === t("common.txt_yes", "Sim");
		const show_shortcut = state.shortcut === t("common.txt_yes", "Sim");
		const { name, restaurant } = state;

		const menu = await api.menus.add({
			name,
			restaurant,
			user: { connect: { id: user.id } },
			show_english,
			show_shortcut,
		});

		if (menu && menu.id) props.onComplete();
		else {
			toast.error("Erro ao criar menu");
			console.log("Erro creating Menu");
		}
	};

	const { loading } = state;

	return (
		<div className={loading ? "edit-menu loading" : "edit-menu"}>
			<h2>{t("admin_add_menu.title", "Criar menu")}</h2>
			<form autoComplete="off">
				<input
					type="text"
					placeholder={t("placeholder.menu_name", "Nome do menu")}
					name="name"
					value={state.name}
					onChange={handleChange}
				/>

				<input
					type="text"
					placeholder={t(
						"placeholder.restaurant_name",
						"Nome do estabelecimento"
					)}
					name="restaurant"
					value={state.restaurant}
					onChange={handleChange}
					className="m-top-4"
				/>
			</form>
			<div className="row m-top-4 drinks">
				<div className="label">
					{process.env.REACT_APP_VERSION === "sb:user:en" ? (
						<h2>
							{t(
								"placeholder.portuguese_version",
								"Versão em Português"
							)}
						</h2>
					) : (
						<h2>
							{t(
								"placeholder.english_version",
								"Versão em Inglês"
							)}
						</h2>
					)}
				</div>
				<RadioGroup
					id="english"
					name="number"
					options={[
						t("common.txt_yes", "Sim"),
						t("common.txt_no", "Não"),
					]}
					selected={state.english}
					onChange={handleToggle}
				/>
			</div>
			<div className="row m-top-4 drinks">
				<div className="label">
					<h2>
						{t(
							"placeholder.drinks_shortcut",
							"Atalho para bebidas"
						)}
					</h2>
				</div>
				<RadioGroup
					id="shortcut"
					name="number"
					options={[
						t("common.txt_yes", "Sim"),
						t("common.txt_no", "Não"),
					]}
					selected={state.shortcut}
					onChange={handleToggle}
				/>
			</div>
			<div className="m-top-8">
				<a href="#" onClick={handleSubmit} className="btn-primary">
					{t("btn.save", "Guardar")}
				</a>
			</div>
		</div>
	);
}

export default withTranslation()(AddMenuContent);
