import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import SpecialStepper from "../partials/SpecialStepper";

import api from "../../../services";

import "./PublishMenuContent.scss";

import { toast } from "react-toastify";

function PublishMenuContent(props) {
	const { t } = props;

	const [state, setState] = useState({
		step: 0,
		name: "",
		city: "",
		postal: "",
		address: "",
		contact: "",
		data: null,
		materials: {},
		loading: true,
		id: props.data.id,
		userId: props.data.userId,
	});

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		const materials = await api.materials.getAll();

		if (materials) {
			for (var i = 0; i < materials.length; i++) {
				materials[i] = { ...materials[i], active: true, value: 0 };
			}
			setState({ ...state, materials, loading: false });
		}
	};

	const handlePublish = (event) => {
		if (event) event.preventDefault();

		setState({ ...state, loading: true });

		// services.menusApi.publishMenuById(this.state.id)
		//     .then(({ updateMenu }) => {
		//         if (updateMenu && updateMenu.id) {
		//             this.onPublished();
		//         } else console.log("Error updating menu");
		//     }).catch(error => console.log(error));
	};

	const handleNext = (event) => {
		event.preventDefault();
		setState({ ...state, step: 1 });
	};

	const validate = () => {
		let { name, address, city, postal, contact } = state;

		const isValidName = name.length < 3 ? null : true;
		if (!isValidName) {
			toast.error("Nome do responsável inválido!");
		}

		const isValidAddress = address.length < 5 ? null : true;
		if (!isValidAddress) {
			toast.error("Morada inválida!");
		}

		const isValidPostal = postal.length < 5 ? null : true;
		if (!isValidPostal) {
			toast.error("Código postal inválido (XXXX-XXX)!");
		}

		const isValidLocal = city.length < 3 ? null : true;
		if (!isValidLocal) {
			toast.error("Localidade inválida!");
		}

		const isValidContact = contact.length < 9 ? null : true;
		if (!isValidContact) {
			toast.error("Contacto inválido!");
		}

		return (
			isValidName &&
			isValidAddress &&
			isValidPostal &&
			isValidLocal &&
			isValidContact
		);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!validate()) return;

		setState({ ...state, loading: true });

		const { materials } = state;

		let data = {},
			item;

		for (var i = 0; i < materials.length; i++) {
			item = materials[i];
			if (item.value) data["product_" + i] = { connect: { id: item.id } };
		}

		const order = await api.orders.add({
			name: state.name,
			address: state.address,
			city: state.city,
			postal: state.postal,
			contact: state.contact,
			menu: { connect: { id: state.id } },
			...data,
		});

		if (order && order.id) {
			setState({ loading: false, step: 2 });
		} else {
			toast.error("Erro ao criar o pedido.");
			console.log("Error creating Order");
		}
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setState({ ...state, [name]: value });
	};

	const handleSteps = (index, id, value) => {
		const { materials } = state;
		const { group } = materials[index];

		materials[index].value = value;

		console.log(index, id, value);

		for (var key in materials) {
			if (materials[key].group == group && key != index) {
				if (value) {
					materials[key].active = false;
					materials[key].value = 0;
				} else materials[key].active = true;
			}
		}

		setState({ ...state, materials });
	};

	const checkStep = () => {
		const { step, materials, loading } = state;

		switch (step) {
			case 0:
				return (
					<div className="select-materials">
						<div className="add-menu-add">
							<h2>
								{t(
									"admin_publish_menu.txt_select_materials",
									"Escolha os materiais de visibilidade"
								)}
							</h2>
						</div>
						<div className="items m-top-8">
							<div className="row m-top-4">
								{materials.map((material, index) => (
									<div className="item" key={material.id}>
										<div className="image">
											<img
												src={material.image.url}
												alt={material.name}
											/>
										</div>
										<h2 className="description">
											{material.name}
										</h2>
										<SpecialStepper
											id={material.id}
											max={material.max}
											onValueChange={(id, value) =>
												handleSteps(index, id, value)
											}
											value={material.value}
											active={material.active}
										/>
									</div>
								))}
							</div>
						</div>

						{checkOrder()}
					</div>
				);
			case 1:
				return (
					<div className={loading ? "add-menu loading" : "add-menu"}>
						<div className="add-menu-add">
							<h2>
								{t(
									"admin_publish_menu.txt_fill_form",
									"Preencha os dados de entrega"
								)}
							</h2>
							<form autoComplete="off">
								<input
									type="text"
									placeholder="Morada"
									name="address"
									onChange={handleChange}
									value={state.address}
								/>

								<input
									type="text"
									placeholder="Código Postal"
									name="postal"
									onChange={handleChange}
									value={state.postal}
									className="m-top-4"
								/>

								<input
									type="text"
									placeholder="Localidade"
									name="city"
									onChange={handleChange}
									value={state.city}
									className="m-top-4"
								/>

								<input
									type="text"
									placeholder="Nome do responsável"
									name="name"
									onChange={handleChange}
									value={state.name}
									className="m-top-4"
								/>

								<input
									type="text"
									placeholder="Contacto"
									name="contact"
									onChange={handleChange}
									value={state.contact}
									className="m-top-4"
								/>
							</form>
							<div className="m-top-8">
								<div
									onClick={handleSubmit}
									className="btn-primary"
								>
									{t(
										"btn.order_materials",
										"Encomendar materiais"
									)}
								</div>
							</div>
						</div>
					</div>
				);
			case 2:
				return (
					<div className="add-menu">
						<div className="add-menu-add">
							<h2>
								Obrigado pela sua encomenda. Vai receber no seu
								estabelecimento num prazo máximo de 5 dias
								úteis.
							</h2>
							<div className="m-top-8">
								<div
									onClick={props.data.onPublished}
									className="btn-primary"
								>
									{t("btn.close", "Fechar")}
								</div>
							</div>
						</div>
					</div>
				);
		}
	};

	const checkOrder = () => {
		const { materials } = state;

		let ordering = false;

		for (var key in materials) {
			if (materials[key].value) {
				ordering = true;
				break;
			}
		}

		if (ordering) {
			return (
				<div className="m-top-8">
					<div onClick={handleNext} className="btn-primary">
						{t("btn.order_materials", "Encomendar materiais")}
					</div>
				</div>
			);
		} else {
			return (
				<div className="m-top-8">
					<div onClick={handlePublish} className="btn-primary">
						{t("btn.no_materials", "Não quero materiais")}
					</div>
				</div>
			);
		}
	};

	const { loading } = state;

	if (!loading) {
		return <div className="publish-menu">{checkStep()}</div>;
	} else {
		return <div className="add-menu loading"></div>;
	}
}

export default withTranslation()(PublishMenuContent);
