import React, { useEffect, useState } from "react";

import { withTranslation } from "react-i18next";
import RadioGroup from "../partials/RadioGroup";

import "./AddItemContent.scss";
import api from "../../../services";

import { toast } from "react-toastify";

function EditItemContent(props) {
	const { t } = props;

	const [state, setState] = useState({
		area: "edit",
		loading: false,
		uploading: false,
		id: props.data.id,
		description_pt: "",
		description_en: "",
		name_pt: "",
		name_en: "",
		parent: props.data.parent,
		show_english: props.data.show_english,
		language: process.env.REACT_APP_VERSION !== "sb:user:en" ? "pt" : "en",
		items: [],
		price: "",
		image: "",
	});

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		const item = await api.items.getById(state.id);

		if (item && item.id) {
			const visible =
				item.visible || item.visible === null
					? t("common.txt_yes", "Sim")
					: t("common.txt_no", "Não");
			setState({ ...state, ...item, visible });
		}
	};

	const validate = () => {
		if (state.items.length > 0 && !state.price) state.price = 0;

		let {
			name_pt,
			name_en,
			description_pt,
			description_en,
			price,
			show_english,
		} = state;

		let isValidNamePT = true;
		if (process.env.REACT_APP_VERSION != "sb:user" || show_english) {
			isValidNamePT = name_pt.length == 0 ? null : true;
			if (!isValidNamePT) toast.error(t("Nome do produto inválido!"));
		}

		let isValidNameEN = true;
		if (show_english) {
			isValidNameEN = name_en.length === 0 ? null : true;
			if (!isValidNameEN)
				toast.error(t("Nome do produto em Inglês não preenchido!"));
		}

		let isValidPrice = true;
		if (!price || price.length == 0) {
			isValidPrice = false;
			if (!isValidPrice) {
				toast.error(t("error_msg.value", "Valor inválido!"));
			}
		}

		// let isValidDescriptionPT = true;
		// if (process.env.REACT_APP_VERSION != "sb:user:en") {
		// 	isValidDescriptionPT = description_pt.length === 0 ? null : true;
		// 	if (!isValidDescriptionPT)
		// 		toast.error(t("Descrição do produto inválido!"));
		// }

		// let isValidDescriptionEN = true;
		// if (show_english) {
		// 	isValidDescriptionEN = description_en.length === 0 ? null : true;
		// 	if (!isValidDescriptionEN)
		// 		toast.error(t("Descrição do produto em Inglês não preenchido!"));
		// }

		return (
			isValidNamePT 
			&& isValidNameEN 
			&& isValidPrice 
			// && isValidDescriptionPT 
			// && isValidDescriptionEN
		);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!validate()) return;

		setState({ ...state, loading: true });

		const {
			name_pt,
			name_en,
			description_pt,
			description_en,
			price,
			image,
		} = state;

		const update = await api.items.update(state.id, {
			name_pt,
			name_en,
			description_pt,
			description_en,
			price: Number(price),
			image,
		});

		if (update && update.id) props.onComplete();
		else console.log("Error updating the Item");
	};

	const handleChange = (event) => {
		let { name, value } = event.target;
		if (name == "price" && value) {
			value = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
		}
		setState({ ...state, [name]: value });
	};

	const handleLanguage = (event) => {
		const { language } = state;
		const { name, value } = event.target;
		setState({ ...state, [name + "_" + language]: value.replace('"', "") });
	};

	const handleLanguageChange = (data) => {
		setState({ ...state, language: data.selected });
	};

	const onFileChanged = (event) => {
		const { files } = event.target;
		const file = files[0];
		const url =
			process.env.REACT_APP_NOT_SECRET_CODE +
			"/amazon?file-name=" +
			file.name +
			"&file-type=" +
			file.type;

		setState({ ...state, uploading: true });

		fetch(url, {
			method: "GET",
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (result) {
				const xhr = new XMLHttpRequest();
				xhr.open("PUT", result.signedRequest, true);
				xhr.onload = function () {
					if (
						this.readyState === XMLHttpRequest.DONE &&
						this.status === 200
					) {
						setState({
							...state,
							uploading: false,
							image: result.url,
						});
					}
				};

				xhr.send(file);
			});
	};

	const removeImage = (event) => {
		event.preventDefault();
		setState({ ...state, image: "" });
	};

	const getIndex = (items, id) => {
		let i = 0;
		for (i; i < items.length; i++) {
			if (items[i].id === id) return i;
		}
	};

	const handleItems = (event) => {
		event.preventDefault();
		setState({ ...state, area: "items" });
	};

	const addItem = async (event) => {
		event.preventDefault();

		const { items } = state;

		setState({ ...state, loading: true });

		const subitem = await api.subitems.add({
			name_pt: "",
			name_en: "",
			price: 0,
			item: { connect: { id: state.id } },
		});

		if (subitem && subitem.id) items.push(subitem);
		setState({ ...state, items, loading: false });
	};

	const deleteItem = async (event) => {
		event.preventDefault();

		const { items } = state;

		const { currentTarget } = event;
		const id = currentTarget.dataset.id;

		const index = getIndex(items, id);

		setState({ ...state, loading: true });

		const subitem = await api.subitems.delete(id);

		if (subitem && subitem.id) items.splice(index, 1);

		setState({ ...state, items, loading: false });
	};

	const handleItemChange = (event) => {
		let { name, value } = event.target;
		const { parentNode } = event.target;
		const { items, language } = state;

		if (name == "price" && value) {
			value = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
		}

		const i = getIndex(items, parentNode.id);

		switch (name) {
			case "name":
				items[i]["name_" + language] = value;
				break;
			case "price":
				items[i].price = value;
				break;
		}

		setState({ ...state, items: items });
	};

	const handleSubmitItems = async (event) => {
		event.preventDefault();
		console.log("MISSING VALIDATIONS");

		setState({ ...state, loading: true });

		const { items } = state;

		let data = [], item;

		for (let i = 0; i < items.length; i++) {
			item = items[i];
			data.push({
				where: { id: item.id },
				data: {
					name_pt: item.name_pt,
					name_en: item.name_en,
					price: Number(item.price),
				},
			});
		}

		const subitems = await api.subitems.updateMany(data);
		if (subitems) setState({ ...state, area: "edit", loading: false });
	};

	const onDeleteMainItem = (event) => {
		event.preventDefault();
		props.onComplete({ ...state, popup: "delete", id: state.id });
	};

	const changeTab = (event) => {
		event.preventDefault();
		props.onComplete({
			...state,
			popup: "change_tab",
			id: state.id,
			tab: state.parent,
		});
	};

	const handleToggle = (event) => {
		setState({ ...state, [event.id]: event.selected });
		if (event.id === "visible") updateVisible(event.selected);
	};

	const updateVisible = (value) => {
		const visible = value === t("common.txt_yes", "Sim");
		api.items.update(state.id, { visible });
	};

	const renderEdit = () => {
		const { show_english, language, loading, uploading, image, parent } =
			state;

		return (
			<div className={loading ? "add-item loading" : "add-item"}>
				<h2>{t("admin_edit_product.title", "Editar produto")}</h2>
				<form autoComplete="off">
					<div className="fields">
						<input
							type="text"
							placeholder={t("placeholder.name", "Nome")}
							name="name"
							onChange={handleLanguage}
							value={state["name_" + language]}
						/>

						<textarea
							type="text"
							placeholder={t(
								"placeholder.description",
								"Descrição"
							)}
							name="description"
							onChange={handleLanguage}
							value={state["description_" + language]}
						/>
					</div>
					<div className="row m-top-4">
						<input
							type="text"
							placeholder="0.00"
							name="price"
							onChange={handleChange}
							value={state.price}
						/>
						{show_english ? (
							<RadioGroup
								id="language"
								name="language"
								options={["pt", "en"]}
								selected={state.language}
								onChange={handleLanguageChange}
							/>
						) : null}
					</div>
					<div className="row">
						<div></div>
					</div>

					<label
						htmlFor="logo"
						className={
							uploading
								? "button button-upload-image upload m-top-4"
								: "button button-upload-image m-top-4"
						}
					>
						<span>
							{image
								? t("btn.image", "imagem.jpg")
								: t(
										"btn.upload_product_img",
										"Carregar imagem do produto"
								  )}
						</span>
						<span>
							{image
								? t(
										"btn.upload_replace",
										"clique para substituir"
								  )
								: t("common.txt_optional", "(opcional)")}
						</span>
						{image ? (
							<a
								href="#"
								className="remove"
								onClick={removeImage}
							>
								<span className="icon-trash"></span>
							</a>
						) : null}
					</label>
					<input
						type="file"
						id="logo"
						onChange={onFileChanged}
						accept="image/x-png,image/gif,image/jpeg"
					/>
				</form>

				<div className="m-top-4">
					<a
						href="#"
						className="variant-button"
						onClick={handleItems}
					>
						<span>
							{t(
								"btn.edit_variant",
								"Editar variantes de produto"
							)}
						</span>
						<span className="icon-edit-bold"></span>
					</a>
				</div>

				<div className="m-top-4">
					<a href="#" className="variant-button" onClick={changeTab}>
						<span>
							{parent && parent.subtab === false
								? t(
										"btn.change_sub_tab",
										"Alterar sub-separador"
								  )
								: t("btn.change_tab", "Alterar separador")}
						</span>
						<span className="icon-edit-bold"></span>
					</a>
				</div>
				<div className="m-top-4">
					<a
						href="#"
						className="delete-button"
						onClick={onDeleteMainItem}
					>
						<span>{t("btn.delete_product", "Apagar produto")}</span>
						<span className="icon-trash"></span>
					</a>
				</div>
				<div className="row m-top-4">
					<div className="label">
						<h2>
							{t("placeholder.public_product", "Produto visível")}
						</h2>
					</div>
					<RadioGroup
						id="visible"
						name="number"
						options={[
							t("common.txt_yes", "Sim"),
							t("common.txt_no", "Não"),
						]}
						selected={state.visible}
						onChange={handleToggle}
					/>
				</div>
				<div className="m-top-4">
					<a href="#" onClick={handleSubmit} className="btn-primary">
						{t("btn.save", "Guardar")}
					</a>
				</div>
			</div>
		);
	};

	const renderItems = () => {
		const { show_english, loading, items, language } = state;

		const onDelete = (e) => deleteItem(e);
		const onChange = (e) => handleItemChange(e);

		return (
			<div className={loading ? "add-variant loading" : "add-variant"}>
				<h2>
					{process.env.REACT_APP_VERSION != "sb:user:en"
						? t(
								"admin_edit_product.txt_variant_of",
								"Variantes de "
						  ) + state.name_pt
						: t(
								"admin_edit_product.txt_variant_of",
								"Variantes de "
						  ) + state.name_en}
				</h2>
				<form autoComplete="off">
					{items.length
						? items.map(function (item) {
								return (
									<div className="variant-item" key={item.id}>
										<div
											className="row m-top-4"
											id={item.id}
										>
											<input
												type="text"
												placeholder={t(
													"placeholder.name",
													"Nome"
												)}
												name="name"
												value={item["name_" + language]}
												onChange={onChange}
											/>
											<input
												type="text"
												placeholder="0.00"
												name="price"
												maxLength="8"
												value={item.price}
												onChange={onChange}
											/>
											<div className="del-variant">
												<div
													href="#"
													data-id={item.id}
													onClick={onDelete}
												>
													<span className="icon-trash"></span>
												</div>
											</div>
										</div>
									</div>
								);
						  })
						: null}
					{items.length < 5 ? (
						<div className="m-top-8">
							<a
								href="#"
								className="variant-button"
								onClick={addItem}
							>
								<span>
									{t(
										"btn.add_variant",
										"Adicionar variante de produto"
									)}
								</span>
								<span className="icon-add"></span>
							</a>
						</div>
					) : null}
					{items.length ? (
						<div className="row m-top-4">
							{show_english &&
							process.env.REACT_APP_VERSION != "sb:user:en" ? (
								<RadioGroup
									id="language"
									name="language"
									options={["pt", "en"]}
									selected={state.language}
									onChange={handleLanguageChange}
								/>
							) : null}
						</div>
					) : null}
					<div className="m-top-4">
						<a
							href="#"
							onClick={handleSubmitItems}
							className="btn-primary"
						>
							{items.length
								? t("btn.save", "Guardar")
								: t("btn.back", "Voltar")}
						</a>
					</div>
				</form>
			</div>
		);
	};

	const { area } = state;

	switch (area) {
		case "edit":
			return renderEdit();
		case "items":
			return renderItems();
		default:
			return null;
	}
}

export default withTranslation()(EditItemContent);
