import { gql } from "@apollo/client";
import initializeApollo from "../lib/initApollo";

const ITEM_DATA = `
    id
    order
    name_pt
    name_en
    description_pt
    description_en
    items {
        id
        name_pt
        name_en
        price
    }
    image
    price
    list {
        id
    }
    visible
`;

const GET = gql`
  query get($id: ID!) {
    item(where: { id: $id }) {
      ${ITEM_DATA}
    } 
  }
`;

const GET_BY_FILTER = gql`
  query get($filter: ItemWhereInput!) {
    items(where: $filter) {
      ${ITEM_DATA}
    }
  }
`;

const CREATE_MUTATION = gql`
	mutation create($data: ItemCreateInput!) {
		createItem(data: $data) {
			id
		}
	}
`;

const UPDATE_MUTATION = gql`
	mutation update($id: ID!, $data: ItemUpdateInput!) {
		updateItem(where: { id: $id }, data: $data) {
			id
		}
	}
`;

const UPDATE_MANY = gql`
	mutation update($data: [ItemUpdateArgs!]!) {
		updateItems(data: $data) {
			id
		}
	}
`;

const DELETE_MUTATION = gql`
	mutation delete($id: ID!) {
		deleteItem(where: { id: $id }) {
			id
		}
	}
`;

const apolloClient = initializeApollo();

const services = {
    getById: async (id) => {
        const {
            data: { item },
        } = await apolloClient.query({
            query: GET,
            variables: { id },
        });

        return item;
    },
    getByList: async (id) => {
        const {
            data: { item },
        } = await apolloClient.query({
            query: GET_BY_FILTER,
            variables: { list: { id } }
        });

        return item;
    },
    update: async (id, data) => {
        const {
            data: { updateItem },
        } = await apolloClient.query({
            query: UPDATE_MUTATION,
            variables: { id, data }
        });

        return updateItem;
    },
    updateMany: async data => {
        const {
            data: { updateItems },
        } = await apolloClient.query({
            query: UPDATE_MANY,
            variables: { data }
        });

        return updateItems;
    },
    delete: async (id) => {
        const {
            data: { deleteItem },
        } = await apolloClient.query({
            query: DELETE_MUTATION,
            variables: { id }
        });

        return deleteItem;
    },
    add: async (data) => {
        const {
            data: { createItem },
        } = await apolloClient.query({
            query: CREATE_MUTATION,
            variables: { data },
        });

        return createItem;
    }
};

export default services;