// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup .delete {
  padding: 10% 5%;
}
.popup .delete h2 {
  font: 2em/1 vigaregular;
  text-align: center;
  color: #5c5c5c;
  margin: 6% 0;
}
.popup .delete .options {
  text-align: center;
  margin: 0 auto;
}
.popup .delete .options .option {
  background-color: #f5f5f5;
  border-radius: 5px;
  border: 0;
  color: #fff;
  font: 1.4em/1 vigaregular;
  color: #5c5c5c;
  transition: all 0.3s;
  padding: 13% 9%;
}
.popup .delete .options .option.selected {
  background-color: #caa36c;
  color: #fff;
}
.popup .delete .options .option:last-child {
  margin-left: 5%;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/popups/DeleteContent.scss"],"names":[],"mappings":"AACI;EACI,eAAA;AAAR;AAEQ;EACI,uBAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;AAAZ;AAGQ;EACI,kBAAA;EACA,cAAA;AADZ;AAGY;EACI,yBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,yBAAA;EACA,cAAA;EACA,oBAAA;EACA,eAAA;AADhB;AAGgB;EACI,yBAAA;EACA,WAAA;AADpB;AAIgB;EACI,eAAA;AAFpB","sourcesContent":[".popup {\n    .delete {\n        padding: 10% 5%;\n\n        h2 {\n            font: 2em/1 vigaregular;\n            text-align: center;\n            color: #5c5c5c;\n            margin: 6% 0;   \n        }\n\n        .options {\n            text-align: center;\n            margin: 0 auto;\n\n            .option {\n                background-color: #f5f5f5;\n                border-radius: 5px;\n                border: 0;\n                color: #fff;\n                font: 1.4em/1 vigaregular;\n                color: #5c5c5c;\n                transition: all 0.3s;\n                padding: 13% 9%;\n\n                &.selected {\n                    background-color: #caa36c;\n                    color: #fff;\n                }\n\n                &:last-child {\n                    margin-left: 5%;\n                }\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
