import { gql } from "@apollo/client";
import initializeApollo from "../lib/initApollo";

const ICON_DATA = `{
    id,
	name,
	image
}`;

const GET_ALL = gql`
    query {
        icons ${ICON_DATA}
    }
`;

const apolloClient = initializeApollo();

const services = {
    getAll: async () => {
		const {
			data: { icons },
		} = await apolloClient.query({
			query: GET_ALL
		});

		return icons;
	},
};

export default services;