const checkBackground = (tab, image, color) => {
    var style = {};

    switch (tab) {
        case 'image':
            style.backgroundImage = `url(${image})`;
            style.backgroundSize = '100% auto';
            break;
        case 'texture':
            style.backgroundImage = `url(/images/theme/textures/${image}.jpg)`;
            style.backgroundPosition = '0 0';
            style.backgroundRepeat = 'repeat-y';
            style.backgroundSize = 'auto';
            break;
        case 'color':
            style.backgroundImage = 'none';
            style.backgroundColor = color;
            break;
        case 'none':
            style.backgroundImage = 'none';
            break;
    }

    return style;
}

const overwriteCustomColor = (menu) => {
    var style = {};

    if(menu.darkMode){
        style.color = "#5C5C5C";;
    }else if (menu.btnColor && !menu.darkMode && menu.btnColor != "#caa36c" ){
        style.color = menu.btnColor;
    }else if (menu.btnColor == "#caa36c"){
        style.color = '#fff';
    }else{
        style.color = {};
    }
    return style;
}

const checkProductBackground = (product) => {
    var style = {};
    style.backgroundImage = `url(/images/theme/products/${product ? product : 'super_bock'}.png)`;
    return style;
}

const styles = {
    checkStyle(menu) {
        return {
            isDarkMode: menu.darkMode,
            background: checkBackground(menu.bgTab, menu.bgImage, menu.bgColor),
            cover: checkBackground(menu.coverTab, menu.coverImage, menu.coverColor),
            text: { color: menu.darkMode ? "#5C5C5C" : "#fff" },
            customText: overwriteCustomColor(menu),
            backgroundProduct: checkProductBackground(menu.product),
            buttons: {
                background: {
                    backgroundColor: menu.btnColor
                },
                border:{
                    borderColor: menu.btnColor
                },
                color: {
                    color: menu.btnColor
                },
                primary:{
                    backgroundColor: menu.btnColor,
                    borderColor: menu.btnColor,
                    color: menu.darkMode ? "#5C5C5C" : "#fff"
                },
                tertiary:{
                    color: menu.btnColor,
                    borderColor: menu.btnColor
                }
            }
        }
    }
};

export default styles;