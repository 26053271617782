import { gql } from "@apollo/client";
import initializeApollo from "../lib/initApollo";

const PARENT_ITEM = `
    id
    name_pt
    name_en
    icon {
        id
        image
    }
`;

const LIST_ITEM = `{
    id
    order
    default
    name_pt
    name_en
    icon {
        name
        image
    }
    subtab
    hide
    parent {
        ${PARENT_ITEM}
    }
}`;

const ITEM_LIST_DATA = `
    id
    default
    name_pt
    name_en
    icon {
        id,
        image
    }
    menu {
        id
        show_english
        bgTab
        bgColor
        bgImage
        btnColor
        darkMode
    }
    items {	
        id
        order
        name_pt	
        name_en	
        description_pt
        description_en
        items {	
            id
            name_pt	
            name_en
            price	
        }	
        image	
        price
        visible
    }
    subtab
    hide
    parent {
        ${PARENT_ITEM}
    }
    tabs ${LIST_ITEM}
`;

const GET = gql`
  query get($id: ID!) {
    itemList(where: { id: $id }) {
      ${ITEM_LIST_DATA}
    }
  }
`;

const GET_BY_FILTER = gql`
  query get($filter: ItemListWhereInput!) {
    itemLists(where: $filter) {
      ${ITEM_LIST_DATA}
    }
  }
`;

const CREATE_MUTATION = gql`
	mutation create($data: ItemListCreateInput!) {
		createItemList(data: $data) {
			id
		}
	}
`;

const UPDATE_MUTATION = gql`
	mutation update($id: ID!, $data: ItemListUpdateInput!) {
		updateItemList(where: { id: $id }, data: $data) {
			id
		}
	}
`;

const UPDATE_MANY = gql`
	mutation update($data: [ItemListUpdateArgs!]!) {
		updateItemLists(data: $data) {
			id
            name_pt
            order
		}
	}
`;

const DELETE_MUTATION = gql`
	mutation delete($id: ID!) {
		deleteItemList(where: { id: $id }) {
			id
		}
	}
`;

const apolloClient = initializeApollo();

const services = {
    getById: async (id) => {
        const {
            data: { itemList },
        } = await apolloClient.query({
            query: GET,
            variables: { id },
        });

        return itemList;
    },
    getByMenu: async (id) => {
        const {
            data: { itemLists },
        } = await apolloClient.query({
            query: GET_BY_FILTER,
            variables: {
                filter: {
                    menu: { id: { equals: id } }
                }
            }
        });

        return itemLists;
    },
    getSubTabs: async (id) => {
        const {
            data: { itemLists },
        } = await apolloClient.query({
            query: GET_BY_FILTER,
            variables: {
                filter: {
                    parent: { id: { equals: id } }
                }
            }
        });

        return itemLists;
    },
    add: async (data) => {
        const {
            data: { createItemList },
        } = await apolloClient.query({
            query: CREATE_MUTATION,
            variables: { data },
        });

        return createItemList;
    },
    addSub: async (data) => {
        const {
            data: { createItemList },
        } = await apolloClient.query({
            query: CREATE_MUTATION,
            variables: { data },
        });

        return createItemList;
    },
    update: async (id, data) => {
        const {
            data: { updateItemList },
        } = await apolloClient.query({
            query: UPDATE_MUTATION,
            variables: { id, data }
        });

        return updateItemList;
    },
    updateMany: async data => {
        const {
            data: { updateItemLists },
        } = await apolloClient.query({
            query: UPDATE_MANY,
            variables: { data }
        });

        return updateItemLists;
    },
    delete: async (id) => {
        const {
            data: { deleteItemList },
        } = await apolloClient.query({
            query: DELETE_MUTATION,
            variables: { id }
        });

        return deleteItemList;
    },
};

export default services;