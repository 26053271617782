import React,{ useEffect, useState } from "react";

import { withTranslation } from 'react-i18next';

import Styles from "../utils/styles";
import Popup from "./popups/Popup";
import Tabs from "./partials/Tabs";

import "./AdminMenu.scss";
import api from "../../services";

function AdminSubTab(props) {
  const { t } = props;

  const [id, setId] = useState(props.match.params.id);

  const [state, setState] = useState({
    popup: null,
    data: null
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const list = await api.itemlist.getById(id);
    setState({ ...state, data: list, popup: null });
  }

  const addTab = e => {
    e.preventDefault();
    const show_english = state.data && state.data.menu.show_english;
    const menu = state.data && state.data.menu;
    setState({ ...state, popup: { name: "add_tab", show_english, parent: id, menu: menu } });
  }

  const editTab = e => {
    e.preventDefault();
    const { currentTarget } = e;
    const show_english = state.data && state.data.menu.show_english;

    setState({
      ...state,
      popup: {
        name: "edit_tab",
        id: currentTarget.dataset.id,
        show_english,
        onDelete: confirmDelete,
      },
    });
  }

  const sortTabs = list => {
    setState({ ...state, popup: { name: "sort_tabs", list: list, onSave: closePopup } });
  }

  const closePopup = e => {
    if (e) e.preventDefault(); getData();
  }

  const confirmDelete = id => {
    setState({
      ...state,
      popup: { name: "delete", id, onDelete: deleteTab },
    });
  }

  const deleteTab = async id => {
    const result = await api.itemlist.delete(id);

    if (result && result.id) closePopup();
    else console.log("Error deleting ItemList");
  }

  const { data, popup } = state;

  if (data) {
    const style = Styles.checkStyle(data.menu);

    return (
      <div className="menu" style={style.background.backgroundColor || style.background.backgroundImage ? { backgroundColor: '#eee' } : null}>
        <div className="app opened" style={style.background}>
          <header className="detail-header">
            <a className="back" href={"/admin/menu/" + data.menu.id}>
              <span className="icon-arrow-back" style={style ? style.customText : {}}></span>
            </a>
          </header>
          <div className="list">
            <div className="title sub" style={style ? style.buttons.background : {}}>
              <img className="sep-icon" src={'/images/icons/' + data.icon.image + '.svg'} style={{ filter: style && style.isDarkMode ? 'invert(0.7)' : 'invert(0)' }} />
              <h2 style={style.text}>{process.env.REACT_APP_VERSION === 'sb:user:en' ? data.name_en : data.name_pt}</h2>
            </div>
          </div>
          <div className="m-top-12">
            <Tabs list={id} items={data.tabs} style={style} addTab={addTab} editTab={editTab} sortTabs={sortTabs} label={data.name_pt} subtab={true}></Tabs>
          </div>
          <div className="m-bottom-10"></div>

          <Popup data={popup} onClose={closePopup} style={style} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="loader">
        <img src="/images/loader.gif" alt="" />
      </div>
    );
  }
}

export default withTranslation()(AdminSubTab);
