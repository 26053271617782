import React from 'react';

const RadioGroup = ({ options, selected, onChange, id, style }) => {

    const handleClick = (sid) => {
        if (selected === sid) return;
        selected = sid;
        onChange({ id: id, selected: sid });
    }

    const render = () => {  
        return (
            <div className="options larger" >
                {options.map(option => (
                    <button type="button" key={option} className={selected === option ? "option selected" : "option"} style={selected === option && style ? style.buttons.background : null}onClick={() => handleClick(option)} >{option}</button>
                ))}
            </div>
        );
    }

    return render();
}

export default RadioGroup;