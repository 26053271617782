import React, { Component } from 'react';

import { DndContext, closestCenter } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import {
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { withTranslation } from 'react-i18next';

import api from '../../../services';

import "./SortContent.scss";

const SortableItem = ({ value }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({
            id: value.id,
        });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            className="list-tab-item"
        >
            {process.env.REACT_APP_VERSION !== "sb:user:en"
                ? value.name_pt
                : value.name_en}
        </div>
    );
};

class SortDetailContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            list: props.data.list
        };

        this.onSave = props.data.onSave;
        this.saveChanges = this.saveChanges.bind(this);
    }

    saveChanges = async (event) => {
        event.preventDefault();

        const { list } = this.state;

        let data = [], item;

		for (let i = 0; i < list.length; i++) {
			item = list[i];
			data.push({
				where: { id: item.id },
				data: { order: i },
			});
		}

        const items = await api.items.updateMany(data);
		if (items) this.onSave();
    }

    onDragEnd = (event) => {
        const { active, over } = event;
        if (active && over && active.id !== over.id) {
            this.setState(({ list }) => {
                const oldIndex = list.findIndex(
                    (item) => item.id === active.id
                );
                const newIndex = list.findIndex((item) => item.id === over.id);
                return { list: arrayMove(list, oldIndex, newIndex) };
            });
        }
    };

    render() {
        const { list } = this.state;
        const { t } = this.props;

        return (
            <div className="sort-tabs">
                <h2>{t('admin_sort_product.title', 'Ordenar produtos')}</h2>
                <DndContext
                    collisionDetection={closestCenter}
                    onDragEnd={this.onDragEnd}
                >
                    <SortableContext
                        items={list.map((item) => item.id)}
                        strategy={verticalListSortingStrategy}
                    >
                        {list.map((value) => (
                            <SortableItem
                                key={value.id}
                                id={value.id}
                                value={value}
                            />
                        ))}
                    </SortableContext>
                </DndContext>
                <div className="m-top-8">
                    <a onClick={this.saveChanges} className="btn-primary">
                        {t('btn.save', 'Guardar')}
                    </a>
                </div>
            </div>
        )
    }
}

export default withTranslation()(SortDetailContent);
