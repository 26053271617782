import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import './Signup.scss';

class Signup extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        const { services } = this.context;

        services.usersApi.validateUserById(id);
    }

    render() {
        const { t } = this.props;
        return (
            <div className="menu">
                <div className="app forms">
                <header className="admin-header">
                    <figure className="logo">
                       <img className="admin-logo" src={t('common.logo_url', '/images/logo.svg')} alt="" />
                    </figure>
                </header>
                <div className="content">
                    <div className="form">
                        <div className="m-bottom-6 txt-primary txt-center">
                            <h2>{t('admin_confirm.txt_thank_you', 'Obrigado por confirmar o seu e-mail')}</h2>
                        </div>
                        <div className="m-top-8 txt-primary txt-center">
                            <h5>{t('admin_confirm.txt_build_menu', 'Quer criar os seus menus digitais? Faça login.')}</h5>
                        </div>
                        <div className="m-top-4">
                            <a href="/login" className="btn-primary">
                                {t('btn.login', 'Login')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default withTranslation()(Signup);