import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "./DeleteContent.scss";

class DeleteContent extends Component {

    constructor(props) {
        super(props);

        this.id = props.data.id;
        this.onDelete = props.data.onDelete;

        this.onConfirm = this.onConfirm.bind(this);
        this.onComplete = props.onComplete;
    }

    onConfirm() { this.onDelete(this.id); } 

    render() {
        const { t } = this.props;
        return (
            <div className="delete">
                <h2>{t('common.txt_delete', 'Tem a certeza que quer apagar?')}</h2>
                <div className="options">
                    <button className="option" onClick={this.onConfirm}>{t('common.txt_yes', 'Sim')}</button>
                    <button className="option" onClick={this.onComplete}>{t('common.txt_no', 'Não')}</button>
                </div>
            </div>
        )
    }
}

export default withTranslation()(DeleteContent);