import materials from './materials';
import itemlist from './itemlist';
import products from './products';
import subitems from './subitems';
import orders from './orders';
import items from './items';
import icons from './icons';
import menus from './menus';
import users from './users';

export const api = {
	materials: { ...materials },
    itemlist: { ...itemlist },
    products: { ...products },
    subitems: { ...subitems },
    orders: { ...orders },
    items: { ...items },
    icons: { ...icons },
    menus: { ...menus },
    users: { ...users },
};

export default api;
