import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import Popup from './popups/Popup';
import api from '../../services';

import './Admin.scss';
import { useAuth } from '../../lib/auth';

const APP_UPD_POPUP = "sb:updates:2";

function Admin(props) {
  const { t } = props;

  const { user } = useAuth();

  const [state, setState] = useState({
    popup: null,
    data: null
  });

  useEffect(() => {
    updateList();
    showUpdatesPopup();
  }, []);

  const updateList = () => {
    api.menus.getAllByUserId(user.id)
      .then((menus) => {
        if (menus) {
          setState({ ...state, data: { menus }, popup: null });
        }
      });
  }

  const showUpdatesPopup = () => {
    if (!localStorage.getItem(APP_UPD_POPUP) && process.env.REACT_APP_VERSION !== 'sb:user:en') {
      setState({ popup: { name: 'app_updates', APP_UPD_POPUP: APP_UPD_POPUP } });
    } else setState({ popup: null });
  }

  const closePopup = e => {
    if (e) e.preventDefault();

    if (state.popup.name === "app_updates") {
      localStorage.setItem(APP_UPD_POPUP, true);
    }

    updateList();
  }

  const editMenu = (e, id) => {
    e.preventDefault();

    setState({
      ...state,
      popup: { name: 'edit_menu', id }
    });
  }

  const addMenu = e => {
    e.preventDefault();
    setState({
      ...state,
      popup: { name: 'add_menu', user: user.id }
    });
  }

  const confirmDeleteMenu = (e, id) => {
    e.preventDefault();

    setState({
      ...state,
      popup: { name: 'delete', id, onDelete: deleteMenu }
    });
  }

  const deleteMenu = async id => {
    const item = await api.menus.delete(id);
    if (item && item.id) updateList();
  }

  const confirmDeleteUser = () => {
    setState({
      ...state,
      popup: { name: 'delete', id: user.id, onDelete: deleteUser }
    });
  }

  const deleteUser = id => {
    //TODO deleteUser
  }

  const showCode = (e, menu) => {
    e.preventDefault();

    const url = menu.url;
    const image = menu.src;

    setState({ ...state, popup: { name: 'qr_code', image, url } });
  }

  const onConfig = e => {
    e.preventDefault();

    setState({ ...state, popup: { name: 'edit_user', onDelete: confirmDeleteUser } });
  }

  const canAddMore = menus => {
    if (menus.length < 3) {
      return (
        <div className="add" onClick={addMenu}>
          <span>{t('btn.add_menu', 'Acrescentar Menu')}</span>
          <span className="icon-add"></span>
        </div>
      );
    }
  }

  const { data, popup } = state;

  if (data && data.menus) {
    return (
      <div className="menu" style={{ backgroundColor: '#eee' }}>
        <div className="app admin">
          <section>
            <header className="admin-header">
              <div className="config"></div>
              <img className="menu-logo" src={t('common.logo_url', '/images/logo.svg')} alt={t('common.site_name', 'Menu Autêntico')} />
              <div className="config" onClick={onConfig}>
                <img src="/images/admin/icons/config.svg" width="100%" height="auto" alt="Configurar conta" />
              </div>
            </header>
            <h2 className="welcome">{t('admin_menus.txt_welcome', 'Bem vindo')},<br />{user.name}</h2>
            <div className="admin-list">
              <div className="title txt-primary">
                <h2>{t('admin_menus.title', 'Gerir Menus')}</h2>
              </div>
              <div className="content">
                <ul>
                  {data.menus.map(menu => (
                    <li key={menu.id}>
                      <h3><a href={"/admin/menu/" + menu.id}>{menu.name}</a></h3>
                      <nav className="actions">
                        <div onClick={(e) => editMenu(e, menu.id)}>
                          <span className="icon-edit-bold"></span>
                        </div>
                        <div onClick={(e) => showCode(e, menu)}>
                          <span className="icon-qrcode"></span>
                        </div>
                        {//todo validate conditional
                          !menu.order.length ?
                            <div onClick={(e) => confirmDeleteMenu(e, menu.id)}>
                              <span className="icon-trash"></span>
                            </div> : ""
                        }
                      </nav>
                    </li>
                  ))}
                </ul>
                <div className="bottom-buttons">
                  {canAddMore(data.menus)}
                </div>
              </div>
              <footer></footer>
            </div>
          </section>
          <Popup data={popup} onClose={closePopup} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="loader">
        <img src="/images/loader.gif" alt="" />
      </div>
    );
  }
}

export default withTranslation()(Admin);