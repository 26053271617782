import { gql } from "@apollo/client";
import initializeApollo from "../lib/initApollo";

const MENU_DATA = `
    id
    name
    qrcode
    url
    restaurant
    bgTab 
    bgColor
    bgImage
    coverTab 
    coverColor
    coverImage
    btnColor
    darkMode
    product
    views
    visible
    published
    show_shortcut
    show_english
    shortcut {
        id
        name_pt
        name_en
        icon {
            id
            image
        }
    }
    lists {
        id
        order
        default
        name_pt
        name_en
        icon {
            id
            image
        }
        parent{
            id
        }
        subtab
        hide
    }
    order {
        id
    }
    logo
`;

const REPORT_DATA = `
    id
    name
    user { 
        name 
        code
        nif
    }
    url
    views
    qrcode
    restaurant
    order { id } 
    published 
    show_english 
    show_shortcut
    createdAt 
`;

const GET = gql`
  query get($id: ID!, $legacyId: String) {
    item: menus(where: { id: { equals: $id } }) {
      ${MENU_DATA}
    }
    legacy: menus(where: { legacyId: { equals: $legacyId } }) {
      ${MENU_DATA}
    }
  }
`;

const GET_REPORT = gql`
  query getAll {
    menus {
      ${REPORT_DATA}
    }
  }
`;

const GET_BY_FILTER = gql`
  query get($filter: MenuWhereInput!) {
    menus(where: $filter) {
      ${MENU_DATA}
    }
  }
`;

const CREATE_MUTATION = gql`
	mutation create($data: MenuCreateInput!) {
		createMenu(data: $data) {
			id
		}
	}
`;

const UPDATE_MUTATION = gql`
	mutation update($id: ID!, $data: MenuUpdateInput!) {
		updateMenu(where: { id: $id }, data: $data) {
			id
		}
	}
`;

const DELETE_MUTATION = gql`
	mutation delete($id: ID!) {
		deleteMenu(where: { id: $id }) {
			id
		}
	}
`;

const apolloClient = initializeApollo();

const services = {
	getById: async (id) => {
		const {
			data: { item, legacy },
		} = await apolloClient.query({
			query: GET,
			variables: { id, legacyId: id },
		});

		if (item.length) return item[0];
		if (legacy.length) return legacy[0];

		return null;
	},
	getReport: async () => {
		const {
			data: { menus },
		} = await apolloClient.query({
			query: GET_REPORT,
		});

		return { allMenus: menus };
	},
	getAllByUserId: async (id) => {
		const {
			data: { menus },
		} = await apolloClient.query({
			query: GET_BY_FILTER,
			variables: {
				filter: {
					user: { id: { equals: id } },
				},
			},
		});

		return menus;
	},
	update: async (id, data) => {
		const {
			data: { updateMenu },
		} = await apolloClient.query({
			query: UPDATE_MUTATION,
			variables: { id, data },
		});

		return updateMenu;
	},
	updateStyle: async (menu) => {
		const {
			data: { updateMenu },
		} = await apolloClient.query({
			query: UPDATE_MUTATION,
			variables: {
				id: menu.id,
				data: {
					bgTab: menu.bgTab,
					bgColor: menu.bgColor,
					bgImage: menu.bgImage,
					coverTab: menu.coverTab,
					coverColor: menu.coverColor,
					coverImage: menu.coverImage,
					btnColor: menu.btnColor,
					darkMode: menu.darkMode,
					product: menu.product,
				},
			},
		});

		return updateMenu;
	},
	delete: async (id) => {
		const {
			data: { deleteMenu },
		} = await apolloClient.query({
			query: DELETE_MUTATION,
			variables: { id },
		});

		return deleteMenu;
	},
	add: async (data) => {
		const {
			data: { createMenu },
		} = await apolloClient.query({
			query: CREATE_MUTATION,
			variables: { data },
		});

		return createMenu;
	},
	publishMenuById: async (id) => {
		const {
			data: { updateMenu },
		} = await apolloClient.query({
			query: UPDATE_MUTATION,
			variables: {
				id,
				data: {
					published: true,
					visible: true,
				},
			},
		});

		return updateMenu;
	},
	updateViews: async (id, views) => {
		const {
			data: { updateMenu },
		} = await apolloClient.query({
			query: UPDATE_MUTATION,
			variables: {
				id,
				data: { views },
			},
		});

		return updateMenu;
	},
};

export default services;
