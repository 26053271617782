import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";

let apolloClient = null;

const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	if (typeof window === "undefined") {
		return headers;
	}

	const token = localStorage?.getItem("auth-token");
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : "",
		},
	};
});

const uploadLink = createUploadLink({
	uri: `${
		process.env.REACT_APP_NOT_SECRET_CODE || "http://localhost:8000"
	}/api/graphql`,
	credentials: "include",
	headers: {
		"Apollo-Require-Preflight": "true",
	},
});

function createApolloClient(req) {
	const inBrowser = typeof window !== "undefined";
	return new ApolloClient({
		ssrMode: !inBrowser,
		connectToDevTools: inBrowser,
		link: authLink.concat(uploadLink),
		cache: new InMemoryCache(),
		defaultOptions: {
			watchQuery: {
				fetchPolicy: "no-cache",
				errorPolicy: "ignore",
			},
			query: {
				fetchPolicy: "no-cache",
				errorPolicy: "all",
			},
		},
	});
}

export default function initializeApollo(initialState = null, req) {
	const _apolloClient = apolloClient ?? createApolloClient(req);

	// If your page has Next.js data fetching methods that use Apollo Client, the initial state
	// gets hydrated here
	if (initialState) {
		_apolloClient.cache.restore(initialState);
	}
	// For SSG and SSR always create a new Apollo Client
	if (typeof window === "undefined") return _apolloClient;
	// Create the Apollo Client once in the client
	if (!apolloClient) apolloClient = _apolloClient;

	return _apolloClient;
}
